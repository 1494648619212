import React from "react";
import styled from "styled-components";
import LandingSection from "./LandingSection";
import Counter from "./Counter";
import Members from "./Members";
import HomeAboutUs from "./HomeAboutUs";
import HomeService from "./HomeService";
import HomeRoadMap from "./HomeRoadMap";
import PlatformView from "./PlatformView";
import HomeReview from "./HomeReview";
import FAQ from "./FAQ";

function Home() {
	return (
		<MainContainer>
			<LandingSection />
			<Counter />
			<Members />
			<HomeAboutUs />
			<HomeService />
			<HomeRoadMap />
			<PlatformView />
			<HomeReview />
			<FAQ />
		</MainContainer>
	);
}

export default Home;

const MainContainer = styled.div``;
const Container = styled.div``;
