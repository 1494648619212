import React, { useState } from "react";
import styled from "styled-components";
import SecondaryHeading from "../../includes/heading/SecondaryHeading";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

function FAQ() {
	const questions = [
		{
			id: 1,
			question:
				"How can GoalFinder's Mapping Tools help students and professionals in their career path selection?",
			answer: "GoalFinder's Mapping Tools offer Self Assessment Tests (SAT) that evaluate personality factors and interests. These assessments, developed by psychologists, assist individuals in determining the most suitable career path based on their unique traits and preferences.",
		},
		{
			id: 2,
			question:
				"What insights can students gain from the SAT Result And Analysis provided by GoalFinder?",
			answer: "The SAT Result And Analysis offered by GoalFinder provide valuable insights into various aspects of an individual's profile, including personality factors, interests, aptitude, career motivation, learning style, and emotional quotient. Understanding these traits can aid in making informed decisions about career choices and personal development.",
		},
		{
			id: 3,
			question:
				"How does GoalFinder's Mentoring program benefit students?",
			answer: "GoalFinder's Mentoring program offers personalized guidance to students, helping them identify and address knowledge gaps and make informed decisions at each stage of their journey. Mentors provide support and direction, ensuring students are on the right track towards their goals.",
		},
		{
			id: 4,
			question:
				"What are Stream Oriented Sessions, and how do they assist students in their career exploration?",
			answer: "GoalFinder forms career clusters through careful scrutiny and classification. Stream Oriented Sessions are tailored to meet the specific needs of these career clusters. Students can join these sessions to gain in-depth knowledge about their chosen fields, fostering a clearer understanding of their potential career paths.",
		},
		{
			id: 5,
			question:
				"How does the Expert Interaction process benefit students aspiring for success in their chosen fields?",
			answer: "The Expert Interaction (EI) process offered by GoalFinder allows students to gain real-life insights and experiences from accomplished professionals in their respective fields. Interacting with eminent individuals provides inspiration, valuable knowledge, and practical guidance to help students on their journey to success.",
		},
		{
			id: 6,
			question:
				"As a parent, how can GoalFinder help me actively participate in my child's career development?",
			answer: "GoalFinder offers a Parental Collaboration Program (PCP) that enables parents to scientifically understand and engage in their child's developmental activities. Through this program, you can play a vital role in supporting your child's growth and career aspirations.",
		},
		{
			id: 7,
			question:
				"What are Skill Enhancement Programs (SEP), and how do they prepare my child for higher studies?",
			answer: "GoalFinder's Skill Enhancement Programs (SEP) consist of master classes and workshops designed to enhance and refine your child's skills in various fields. These programs aim to upskill and reskill your child, making them better prepared for pursuing higher studies and future professional endeavors.",
		},
		{
			id: 8,
			question:
				"How does GoalFinder regularly monitor my child's progress in their personal and career development?",
			answer: "The Progress Mapping Program at GoalFinder evaluates your child's progress on a regular basis. This comprehensive assessment monitors their personality factors, learning styles, aptitude, career interests, motivators, and emotional quotient, ensuring they are on track to achieve their goals.",
		},
		{
			id: 9,
			question:
				"Can you explain the Expert Interaction process and how it benefits my child's aspirations?",
			answer: "GoalFinder's Expert Interaction (EI) process allows your child to interact with highly accomplished professionals in various fields. These experts share their real-life experiences and knowledge, inspiring and guiding your child towards success in their chosen career paths.",
		},

		{
			id: 10,
			question:
				" How do I get started with GoalFinder's services for my child?",
			answer: "Getting started with GoalFinder is easy! Simply sign up on our website and access the Mapping Tools to initiate the Self Assessment Tests (SAT) for your child. From there, our services, including mentoring, stream-oriented sessions, expert interactions, and skill enhancement programs, will be available to help your child embark on a journey of self-discovery and growth.",
		},
	];

	const [selectedQuestion, setSelectedQuestion] = useState(1);

	return (
		<MainContainer>
			<div className="wrapper">
				<Container>
					<HeadingContainer>
						<SecondaryHeading value="Ask Now" left={true} />
						<HeadingDiv>
							<PrimaryHeading
								value={"Frequently Ask"}
								color="#0A0A0A"
							/>
							<PrimaryHeading
								value={"Questions"}
								color="#4582f7"
							/>
						</HeadingDiv>
					</HeadingContainer>
					<QuestionContainer>
						{questions.map((item, i) => (
							<QuestionDiv key={i}>
								<Question
									onClick={() => {
										if (selectedQuestion === item.id) {
											setSelectedQuestion("");
										} else {
											setSelectedQuestion(item.id);
										}
									}}
								>
									<Left
										className={
											selectedQuestion === item.id
												? "selected"
												: ""
										}
									>
										{item.question}
									</Left>
									<Right>
										<Icon
											className={
												selectedQuestion === item.id
													? "selected"
													: ""
											}
											src={
												require("./../../../assets/icons/angle-arrow.svg")
													.default
											}
											alt="icon"
										/>
									</Right>
								</Question>
								<Answer
									className={
										selectedQuestion === item.id
											? "selected"
											: ""
									}
								>
									{item.answer}
								</Answer>
							</QuestionDiv>
						))}
					</QuestionContainer>
				</Container>
			</div>
		</MainContainer>
	);
}

export default FAQ;

const MainContainer = styled.div``;
const Container = styled.div`
	padding: 100px 40px 40px;
	@media all and (max-width: 640px) {
		padding: 60px 0px 30px;
	}
`;
const HeadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const HeadingDiv = styled.div`
	display: flex;
	align-items: center;
	h2:nth-child(1) {
		margin-right: 10px;
	}
	@media all and (max-width: 360px) {
		display: inline-block;

		h2 {
			text-align: center;
		}
	}
`;
const QuestionContainer = styled.div`
	padding: 30px 40px;
	@media all and (max-width: 480px) {
		padding: 20px;
	}
`;
const QuestionDiv = styled.div`
	border-bottom: 1px solid #d4d4d4;
	padding: 20px 0;
	:last-child {
		border: none;
	}
`;
const Question = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Left = styled.h4`
	color: #0a0a0a;
	font-size: 16px;
	font-family: "DMSans-Medium";

	&.selected {
		color: #3182ff;
	}
`;
const Right = styled.div`
	cursor: pointer;
`;
const Icon = styled.img`
	height: 20px;
	width: 20px;
	transition: all 0.3s linear;

	&.selected {
		transform: rotateZ(180deg);
		transition: max-height 0.3s ease;
	}
`;
const Answer = styled.p`
	color: #747474;
	font-size: 14px;
	max-height: 0;
	overflow: hidden;
	transition: 0.3s;
	margin-top: 0;
	width: 90%;

	&.selected {
		margin-top: 10px;
		max-height: 500px;
		overflow: hidden;
		transition: max-height 0.3s ease;
	}
`;
