import React, { useEffect } from "react";
import styled from "styled-components";
import SecondaryHeading from "../../includes/heading/SecondaryHeading";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

//aos
import AOS from "aos";
import "aos/dist/aos.css";

function HomeRoadMap() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	const RoadMapData = [
		{
			id: "01",
			title: "Mapping Tools",
			description:
				"Self Assessment Tests-SAT help students to determine the career path they should follow according to their personality factors and interests. We use proper career assessment tools developed by psychologists.",
			image: require("./../../../assets/icons/map-tool.svg").default,
		},
		{
			id: "02",
			title: "SAT Result And Analysis",
			description:
				"The results of the SAT help in identifying the personality factors, interests, aptitude, suitability, career motivation, learning style, and emotional quotient of the student.",
			image: require("./../../../assets/icons/sat.svg").default,
		},
		{
			id: "03",
			title: "Mentoring",
			description:
				"Mentoring process lights up the way of each child ahead where support is needed. The mentor helps the child to identify and correct the gaps in knowledge, take informed decisions at each and every stage.",
			image: require("./../../../assets/icons/mentor.svg").default,
		},
		{
			id: "04",
			title: "Stream Oriented Sessions",
			description:
				"Students are allowed to join career clusters formed through the process of careful scrutiny and classification. Sessions are delivered based upon the identified needs of career clusters.Each session.",
			image: require("./../../../assets/icons/stream.svg").default,
		},
		{
			id: "05",
			title: "Expert Interaction",
			description:
				"EI process has been formulated to deliver the real life experience. Persons of eminence who are highly accomplished in their respective fields of activity, interact on relevant topics during this process.",
			image: require("./../../../assets/icons/expert.svg").default,
		},
		{
			id: "06",
			title: "Parental Collaboration Program",
			description:
				"Parents are the primary mentors of their children. The life of a child is greatly enhanced by the proper involvement of parents in their developmental activities. The PCP will enable the parents to scientifically understand.",
			image: require("./../../../assets/icons/parental.svg").default,
		},
		{
			id: "07",
			title: "Skill Enhancement Programs",
			description:
				"Through the master classes and workshops in different fields, the SEP works on upskilling and reskilling of the child to make them ready to start their professional higher studies.",
			image: require("./../../../assets/icons/skill.svg").default,
		},
		{
			id: "08",
			title: "Progress Mapping Program",
			description:
				"The progress of the student is evaluated regularly. This helps in monitoring the development of the child in terms of personality factors, learning styles, aptitude, career interests, motivators, and emotional quotient.",
			image: require("./../../../assets/icons/progress.svg").default,
		},
	];

	return (
		<MainContainer
		// data-aos="fade-up"
		>
			<div className="wrapper">
				<HeadingContainer>
					<SmallHeadingDiv>
						<SecondaryHeading value="RoadMap" left={true} />
					</SmallHeadingDiv>
					<PrimaryHeading value="The Best Roadmap to Your" />
					<PrimaryHeading value="Child's Dreams" color="#4582f7" />
				</HeadingContainer>
				<Container>
					{RoadMapData.map((item) => (
						<Card key={item.id} data-aos="zoom-in-down">
							<TopDIv>
								<Top>
									<Left>
										<Icon src={item.image} alt="icon" />
									</Left>
									<Right>{item.id}</Right>
								</Top>
							</TopDIv>
							<Bottom>
								<Title>{item.title}</Title>
								<Description>{item.description}</Description>
							</Bottom>
						</Card>
					))}
				</Container>
			</div>
		</MainContainer>
	);
}

export default HomeRoadMap;

const MainContainer = styled.div`
	background-color: #fff;
	padding: 80px 0;
`;
const HeadingContainer = styled.div`
	margin: 40px 0;

	h2 {
		text-align: center;
	}

	@media all and (max-width: 480px) {
		margin: 20px 0;
	}
`;
const SmallHeadingDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const Container = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 2%;
	padding: 30px 0;
	justify-content: center;
	/* display: table; */
`;
const Bottom = styled.div`
	padding: 15px 0;
`;

const Card = styled.div`
	border: 1px solid rgba(212, 212, 212, 0.3);
	border-right: 1px solid rgba(212, 212, 212, 1);
	border-bottom: 1px solid rgba(212, 212, 212, 1);
	border-radius: 6px;
	padding: 20px;
	width: 32%;
	margin-bottom: 2%;
	height: 360px;
	/* display: table-cell; */

	/* display: flex;
	flex-direction: column;
	justify-content: space-between; */
	/* height: 340px; */
	/* display: table; */

	@media all and (max-width: 1024px) {
		width: 48%;
	}

	@media all and (max-width: 640px) {
		width: 100%;
		height: max-content;
	}

	&:hover {
		border: 1px solid rgba(69, 130, 247, 0.3);
		border-right: 1px solid rgba(69, 130, 247, 1);
		border-bottom: 1px solid rgba(69, 130, 247, 1);
		${Bottom} {
			transition: 0.3s;
			transform: translateY(-5px);
		}
	}
`;
const TopDIv = styled.div`
	height: 100px;
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* margin-bottom: 12px; */
`;
const Left = styled.div`
	height: 80px;
	width: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #e6edfa;
	border-radius: 50%;
`;
const Icon = styled.img``;
const Right = styled.h3`
	font-size: 68px;
	-webkit-text-stroke: 0.5px black;
	-webkit-text-fill-color: white;
	@media all and (max-width: 480px) {
		font-size: 48px;
	}
`;
const Title = styled.h5`
	color: rgba(10, 10, 10, 1);
	font-size: 18px;
	margin-bottom: 8px;
`;
const Description = styled.p`
	font-size: 16px;
	color: rgba(116, 116, 116, 1);

	@media all and (max-width: 480px) {
		font-size: 15px;
	}
`;
