import React, { useEffect } from "react";
import styled from "styled-components";
import bgImage from "./../../../assets/images/home-aboutus.png";
import SecondaryHeading from "../../includes/heading/SecondaryHeading";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

//aos
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function HomeAboutUs() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	return (
		<MainContainer
		// data-aos="fade-up"
		>
			<div className="wrapper">
				<Container>
					<LeftDiv>
						<LogoDiv>
							<Logo
								src={require("./../../../assets/images/logo.png")}
							/>
						</LogoDiv>
					</LeftDiv>
					<RightDiv>
						<SecondaryHeading value={"About Us"} />

						<PrimaryHeading value={"Discover the Story Behind"} />
						<PrimaryHeading value={"GoalFinder"} color="#4582f7" />

						<Paragraph>
							De' Lead International was established with the
							vision of positively contributing to society by
							identifying and developing the human resource's
							innate talents and guiding them towards their career
							goals by utilizing their strengths, interests, and
							passions. As a team of visionary social leaders, we
							strive to provide our clients with the best possible
							service through passion and dedication
						</Paragraph>
						<Paragraph>
							The team is headed by the visionary leader Dr Thomas
							George popularly known as “ Thomman”, the chairman,
							who is the founder of a premium Business School –
							Leads College, based in the State of Kerala, India.
						</Paragraph>

						<ButtonDiv>
							<PrimaryButton href="/about">
								View More
							</PrimaryButton>
						</ButtonDiv>
					</RightDiv>
				</Container>
			</div>
		</MainContainer>
	);
}

export default HomeAboutUs;

const MainContainer = styled.div``;
const Container = styled.div`
	padding: 80px 0;
	display: flex;
	align-items: start;
	justify-content: space-between;
	@media all and (max-width: 768px) {
		align-items: center;
		padding: 100px 0;
	}
	@media all and (max-width: 768px) {
		flex-direction: column-reverse;
		padding-top: 50px;
		padding-bottom: 80px;
	}
`;
const LeftDiv = styled.div`
	background-image: url(${bgImage});
	width: 45%;
	height: 500px;
	border-radius: 12px;
	position: relative;
	background-size: contain;
	background-repeat: no-repeat;
	@media all and (max-width: 768px) {
		width: 100%;
		height: 450px;
		background-size: cover;
		overflow: hidden;
	}
`;
const LogoDiv = styled.div`
	background-color: #fff;
	height: 80px;
	width: 200px;
	position: absolute;
	right: -35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	top: 40px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

	@media all and (max-width: 768px) {
		height: 60px;
		width: 140px;
	}
`;
const Logo = styled.img`
	height: 50px;
	width: 140px;
	display: inline-block;
	@media all and (max-width: 768px) {
		height: 40px;
		width: 100px;
	}
`;
const RightDiv = styled.div`
	width: 45%;
	@media all and (max-width: 768px) {
		width: 100%;
		margin-bottom: 30px;
	}
`;
const Paragraph = styled.p`
	color: #747474;
	font-size: 16px;
	margin: 20px 0;
	@media all and (max-width: 480px) {
		font-size: 15px;
	}

	/* width: 90%; */
`;
const ButtonDiv = styled.div`
	display: flex;
	align-items: center;
`;
const PrimaryButton = styled.a`
	color: #fff;
	border: 1px solid #4582f7;
	background-color: #4582f7;
	border-radius: 6px;
	height: 50px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 16px;
	margin-right: 15px;
`;
