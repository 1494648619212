import React from "react";
import styled from "styled-components";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

function AboutUsLanding() {
	return (
		<MainContainer>
			<EmptyDiv />
			<div className="wrapper">
				<Container>
					<LandingContainer>
						<LandingLeft>
							<Line />
							<PrimaryHeading value="Discover the Story Behind" />
							<PrimaryHeading
								color="#4582f7"
								value="GoalFinder"
							/>
							<Paragraph>
								The goalfinder team strives to provide
								exceptional service to its clients at all times.
								Our services are tailored to meet your specific
								needs. We guarantee that we can fully meet your
								self improvement needs through proper self
								evaluation and career crafting
							</Paragraph>
							<Paragraph>
								Planning for higher education as well as
								developing a career plan are essential parts of
								any student's education. Success in life is a
								result of choosing the right career, which in
								turn leads to happiness in one's life. In many
								instances, the decision to pursue a career and
								study path is not a conscious and scientific
								one. A number of factors may contribute to this;
								for example, a lack of awareness, peer group
								influences, emotional pressure, and social
								pressure. Our goal is to assist children in
								identifying their true potential by mapping
								their skills, interests, and personality traits.
								We developed this tool at Goal Finder as a means
								of helping them identify their true potential.
								By taking this test, a child will be able to
								identify those areas in which he has a high
								probability of success. Furthermore, it
								identifies moderate areas of interest as well as
								areas that should not be pursued.
							</Paragraph>
						</LandingLeft>
						<LandingRight>
							<LogoDiv>
								<Logo
									src={require("./../../../assets/images/logo-big.png")}
								/>
							</LogoDiv>
						</LandingRight>
					</LandingContainer>
				</Container>
			</div>
		</MainContainer>
	);
}

export default AboutUsLanding;

const MainContainer = styled.div``;

const EmptyDiv = styled.div`
	background-color: #f5f8ff;
	position: absolute;
	top: 0;
	z-index: -1;
	right: 0;
	width: 0;
	height: 0;
	transition: width height 0.3s;
	animation: 0.8s in-out forwards;

	@keyframes in-out {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			height: 400px;
			width: 31%;
		}
	}

	@media all and (max-width: 1024px) {
		display: none;
	}
`;
const Container = styled.div``;
const LandingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	/* flex-direction: row; */
	/* height: calc(789px - 100px); */
	min-height: calc(100vh - 120px);
	margin-top: 20px;

	@media all and (max-width: 1024px) {
		align-items: start;
	}
	@media all and (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;
const LandingLeft = styled.div`
	width: 60%;
	padding: 0 30px;

	@media all and (max-width: 768px) {
		width: 100%;
	}
`;
const LandingRight = styled.div`
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media all and (max-width: 768px) {
		width: 100%;
		margin-bottom: 30px;
		background-color: #f5f8ff;
		padding: 40px 0;
	}
`;
const Line = styled.div`
	background-color: #76b82a;
	width: 70px;
	height: 8px;
	border-radius: 4px;
	margin-bottom: 15px;
`;
const Paragraph = styled.p`
	color: #747474;
	font-size: 18px;
	margin: 20px 0 40px;

	&:last-child {
		margin: 0;
	}

	@media all and (max-width: 768px) {
		font-size: 16px;
	}
	@media all and (max-width: 480px) {
		font-size: 15px;
	}
`;
const LogoDiv = styled.div`
	height: 60%;
	width: 60%;
	@media all and (max-width: 768px) {
		height: 25%;
		width: 25%;
	}
`;
const Logo = styled.img`
	height: 100%;
	width: 100%;
`;
