import React from "react";
import styled from "styled-components";
import SecondaryHeading from "../../includes/heading/SecondaryHeading";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

function BoardMember() {
	const members = [
		{
			id: 1,
			name: "Prof Dr. Alli Rani",
			position: "Bureaucrat and Institutional Builder",
			details:
				"Retired IAS officer, Former Secretary in the Union HRD ministry. Currently Director M/s Saradar Vallabhbhai Patel Institute of Textile Management, Coimbatore, Ministry of textiles Govt of India.",
		},
		{
			id: 2,
			name: "Prof. Balasubramanian S",
			position:
				"IIM-C Alumnus, Chief Mentor, Director of Centre for Advanced Studies Coimbatore, Fellow of AIMA",
			details:
				"IIM-C Alumnus, Chief Mentor, Director of Centre for Advanced Studies Coimbatore, Fellow of AIMA.",
		},
		{
			id: 3,
			name: "Dr. Chandrsekara Menon",
			position:
				"Academician, Professor at Lead College of Management, Palakkad",
			details:
				"M.B.A., M.A (Eco)., M.A.(Socio). F.A.M (Fellowship in Advanced Marketing) , M.Phil, Ph.D. Formerly Centre Head at S.P Jain Centre for Management , Dubai and Singapore.",
		},
		{
			id: 4,
			name: "Mr. Jiji Thomson",
			position: "Bureaucrat and Institutional Builder",
			details:
				"IIM -A Alumnus, Retired IAS officer and former Chief Secretary Govt of Kerala. Currently Board Member of M/s. Cochin Shipyard, Kerala, India.",
		},
		{
			id: 5,
			name: "Dr. Prathapan K",
			position: "Academician turned Administrator",
			details:
				"Vice Chancellor, D Y Patil Agriculture & Technical University, India. Former Director, National Horticultural Mission-Kerala & MD of PSU's Govt. of Kerala, Professor, Kerala Agricultural University.",
		},
		{
			id: 6,
			name: "Dr. Rahul Menon",
			position: "Doctor turned Management Expert",
			details:
				"CEO- Apollo Hospitals and Director Medical Services Chennai.",
		},
		{
			id: 7,
			name: "CA. V. Sathyanarayanan,",
			position:
				"Financial Advisor, Business Strategist, Management Consultant and a Social Leader",
			details:
				"FCA, Joint Managing Partner, Varma & Varma Chartered Accountants, India. Vice Chairman, Adarsh Charitable Trust.",
		},
		{
			id: 8,
			name: "Dr. Sreekanth S V",
			position:
				"Academic Quality Advisor, Trainer and Behavioral Science Expert",
			details:
				"Alumnus of IIM- A and Symbiosis International University, Former Director DC College of Management and Technology, Kerala.",
		},
		{
			id: 9,
			name: "Dr. Thomas George K",
			position:
				"Chairman, Lead College of Management, Psychologist and renowned Trainer",
			details:
				"Chairman and Director Lead College of Management, Guinness World Record Holder.",
		},
	];

	return (
		<MainContainer>
			<div className="wrapper">
				<Container>
					<Top>
						<SecondaryHeading
							value={"Message From"}
							// lineColor={"#D4D4D4"}
							left={true}
						/>
						<HeadingDiv>
							<PrimaryHeading
								value={"Board of"}
								color="#0A0A0A"
							/>
							<PrimaryHeading
								value={"Governors"}
								color="#4582f7"
							/>
						</HeadingDiv>
					</Top>
					<Body>
						{members.map((item, i) => (
							<Card key={i}>
								<ImageDiv>
									<Image
										src={require("./../../../assets/images/person-1.jpg")}
									/>
								</ImageDiv>
								<div>
									<Name>{item.name}</Name>
									<Designation>{item.position}</Designation>
									<Description>{item.details}</Description>
								</div>
							</Card>
						))}
					</Body>
				</Container>
			</div>
		</MainContainer>
	);
}

export default BoardMember;

const MainContainer = styled.div``;
const Container = styled.div`
	padding: 100px 0;
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const HeadingDiv = styled.div`
	display: flex;
	align-items: center;
	h2:nth-child(1) {
		margin-right: 10px;
	}
`;
const Body = styled.div`
	/* display: grid;
	grid-template-columns: 1fr 1fr 1fr; */
	display: flex;
	justify-content: center;
	align-items: start;
	flex-wrap: wrap;
	gap: 40px 20px;
	/* gap: 3%; */
	margin-top: 40px;
`;
const Card = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* margin-bottom: 40px; */
	width: 30%;
	/* padding: 30px 0; */
	@media all and (max-width: 1024px) {
		width: 48%;
	}
	@media all and (max-width: 640px) {
		width: 100%;
	}
`;
const ImageDiv = styled.div`
	height: 200px;
	width: 200px;
	margin-bottom: 15px;
	@media all and (max-width: 1024px) {
		height: 200px;
		width: 200px;
	}
`;
const Image = styled.img`
	height: 100%;
	border-radius: 50%;
	width: 100%;
	object-fit: cover;
`;
const Name = styled.h3`
	color: #0a0a0a;
	font-family: "DMSans-Medium";
	text-align: center;
	font-size: 18px;
	margin: 15px 0 10px;
`;
const Designation = styled.h4`
	color: #4582f7;
	font-family: "DMSans-Regular";
	text-align: center;
	font-size: 18px;
`;
const Description = styled.p`
	color: #747474;
	text-align: center;
	font-size: 16px;
	margin-top: 10px;
	width: 85%;

	@media all and (max-width: 48px) {
		font-size: 15px;
	}
`;
