import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

//style
import "./Header.css";
import ResponsiveNavbar from "./ResponsiveNavbar";

function Header() {
	const [isNavBar, setIsNavBar] = useState(false);

	return (
		<MainContainer>
			{/* <EmptyContainer className="wrapper">
				<EmptyDiv />
			</EmptyContainer> */}
			<div className="wrapper">
				<Container className="red">
					<LeftDiv>
						<H1>
							<LogoLink to="/">
								<LogoImage
									src={require("./../../../assets/images/logo.png")}
									alt="logo"
								/>
							</LogoLink>
						</H1>
					</LeftDiv>
					<RightDiv>
						<NavLink
							className={({ isActive }) =>
								isActive
									? "nav-active nav-default"
									: "nav-default"
							}
							to="/"
						>
							Home
						</NavLink>
						<NavLink
							className={({ isActive }) =>
								isActive
									? "nav-active nav-default"
									: "nav-default"
							}
							to="/about"
						>
							About Us
						</NavLink>
						{/* <NavLink
							className={({ isActive }) =>
								isActive
									? "nav-active nav-default"
									: "nav-default"
							}
							to="/our-teams"
						>
							Our Teams
						</NavLink> */}
						<NavLink
							className={({ isActive }) =>
								isActive
									? "nav-active nav-default"
									: "nav-default"
							}
							to="/contact-us"
						>
							Contact Us
						</NavLink>
						<RegisterButton to="/register">
							Register Now
						</RegisterButton>
					</RightDiv>
					<MenuDiv
						onClick={() => {
							setIsNavBar(true);
						}}
					>
						<MenuIcon
							src={
								require("./../../../assets/icons/menu.svg")
									.default
							}
							alt="menu"
						/>
					</MenuDiv>
				</Container>
			</div>
			{isNavBar && <ResponsiveNavbar setIsNavBar={setIsNavBar} />}
		</MainContainer>
	);
}

export default Header;

const MainContainer = styled.div``;

const Container = styled.div`
	height: 100px;
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const LeftDiv = styled.div``;
const H1 = styled.h1``;
const LogoLink = styled(Link)``;
const LogoImage = styled.img`
	display: block;
	height: 60px;
	width: 170px;

	@media all and (max-width: 480px) {
		height: 50px;
		width: 140px;
	}
`;
const RightDiv = styled.div`
	display: flex;
	align-items: center;

	@media all and (max-width: 1024px) {
		display: none;
	}
`;
const CustomNavLink = styled(NavLink)`
	color: #747474;
	font-size: 16px;
	margin-right: 30px;
	font-family: "DMSans-Medium";

	:hover {
		text-decoration: underline;
		cursor: pointer;
		color: #0a0a0a;
	}
`;
const RegisterButton = styled(NavLink)`
	color: #4582f7;
	border: 1px solid #4582f7;
	background-color: #fff;
	border-radius: 4px;
	height: 45px;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 16px;
`;
const EmptyContainer = styled.div`
	position: relative;
`;
const EmptyDiv = styled.div`
	background-color: #ffeece;
	height: 789px;
	width: 600px;
	position: absolute;
	top: 0;
	z-index: -1;
	right: -220px;
`;
const MenuDiv = styled.div`
	display: none;
	@media all and (max-width: 1024px) {
		display: inline-block;
	}
`;
const MenuIcon = styled.img`
	height: 48px;
	width: 48px;

	@media all and (max-width: 768px) {
		height: 36px;
		width: 36px;
	}
`;
