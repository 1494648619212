import React from "react";
import styled from "styled-components";

function PrimaryHeading({ value, color = null }) {
	return <Heading color={color}>{value}</Heading>;
}

export default PrimaryHeading;

const Heading = styled.h2`
	/* color: #0a0a0a; */

	color: ${(props) => (props.color ? props.color : "#0a0a0a")};
	font-size: 36px;
	font-family: "DMSans-bold";

	&.blue {
		color: #4582f7;
	}

	@media all and (max-width: 1024px) {
		font-size: 30px;
	}
	@media all and (max-width: 768px) {
		/* width: 100%; */
		font-size: 28px;
	}
	@media all and (max-width: 480px) {
		font-size: 22px;
	}
`;
