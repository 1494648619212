import React from "react";
import styled from "styled-components";

function CancelButton({ onClick }) {
	return <Button onClick={onClick}>Cancel</Button>;
}

export default CancelButton;

const Button = styled.button`
	/* border: 1px solid #4582f7; */
	height: 48px;
	width: max-content;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 16px;
	cursor: pointer;
	/* background: #4582f7; */
	color: #747474;
	font-size: 16px;
`;
