import React from "react";
import styled from "styled-components";

// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Members() {
	const members = [
		{
			id: 1,
			name: "Vijay",
			designation: "Parent",
			image: require("./../../../assets/images/vijay.png"),
		},
		{
			id: 2,
			name: "Amruth KS",
			designation: "MBA Graduate",
			image: require("./../../../assets/images/amruth.png"),
		},
		{
			id: 3,
			name: "Mr. Arjun Govind",
			designation: "Assistant Professor-HR",
			image: require("./../../../assets/images/arjun-1.png"),
		},
		{
			id: 4,
			name: "Dr. Bindhu Ann Thomas",
			designation: "Director, Kochi Business School",
			image: require("./../../../assets/images/bindhu-1.png"),
		},
		{
			id: 5,
			name: "Dimple Prabhoth",
			designation: "Parent & Teacher, UAE",
			image: require("./../../../assets/images/dimple-1.png"),
		},
		{
			id: 6,
			name: "Gokul Krishna",
			designation: "Student Grade 11",
			image: require("./../../../assets/images/gokul-1.png"),
		},
		// {
		// 	id: 10,
		// 	name: "Daniel Hamilton",
		// 	designation: "Product Designer",
		// 	image: require("./../../../assets/images/person-3.jpg"),
		// },
	];

	const settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 1500,
		pauseOnHover: false,
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 641,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			// {
			// 	breakpoint: 481,
			// 	settings: {
			// 		slidesToShow: 1,
			// 		slidesToScroll: 1,
			// 	},
			// },
		],
	};

	return (
		<MainContainer>
			<div className="wrapper">
				<Slider {...settings}>
					{members.map((item) => (
						<Container key={item.id}>
							<Card>
								<Image src={item.image} alt="members" />
								<Name>{item.name}</Name>
								<Designation>{item.designation}</Designation>
							</Card>
						</Container>
					))}
				</Slider>
			</div>
		</MainContainer>
	);
}

export default Members;

const MainContainer = styled.div`
	padding: 40px 0;
`;
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Card = styled.div`
	border: 1px solid #eeeeee;
	padding: 30px 15px;
	border-radius: 4px;
	width: 90% !important;
	height: 300px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: start;

	@media all and (max-width: 480px) {
		height: 230px;
		padding: 20px;
		justify-content: center;
	}

	&:hover {
		background-color: #f5f8ff;
	}
`;
const Image = styled.img`
	height: 150px;
	width: 150px;
	border-radius: 50%;
	display: inline-block;
	object-fit: cover;

	@media all and (max-width: 480px) {
		height: 100px;
		width: 100px;
	}
`;
const Name = styled.h5`
	text-align: center;
	color: #0a0a0a;
	font-size: 18px;
	margin-top: 5px;

	@media all and (max-width: 480px) {
		font-size: 16px;
	}
`;
const Designation = styled.p`
	text-align: center;
	color: #747474;
	font-size: 14px;
	@media all and (max-width: 480px) {
		font-size: 13px;
	}
`;
