import React, { useState, forwardRef, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "./../../../assets/icons/calendar.svg";

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
	const [month, day, year] = value.split("/");

	return (
		<DatePickerContainer>
			<DatePickerInput
				type="text"
				value={month && day && year ? `${day}/${month}/${year}` : ""}
				placeholder="DD / MM /YYYY"
				readOnly
			/>
			<DatePickerIcon
				src={CalendarIcon}
				alt="Calendar"
				onClick={onClick}
				ref={ref}
			/>
		</DatePickerContainer>
	);
});

const CustomInputDatePicker = ({ label, value, setValue }) => {
	const [selectedDate, setSelectedDate] = useState(null);

	return (
		<Container>
			<Label>{label}</Label>
			<DatePicker
				selected={value}
				onChange={(date) => {
					setValue(date);
				}}
				customInput={<ExampleCustomInput />}
			/>
		</Container>
	);
};

export default CustomInputDatePicker;

const Container = styled.div`
	width: 100%;
	display: block;
`;
const Label = styled.label`
	color: #6b6b6b;
	font-size: 15px;
	display: block;
	margin-bottom: 5px;
`;
const DatePickerContainer = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	display: block;
`;

const DatePickerInput = styled.input`
	padding-left: 12px;
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	width: 100%;
	height: 48px;
	color: #494a4a;
	font-size: 16px;

	&::placeholder {
		color: #adadad;
	}
`;

const DatePickerIcon = styled.img`
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
	cursor: pointer;
	z-index: 1;
`;
