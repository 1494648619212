import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

// Define the list of country codes and flags
import countryData from "./../../../assets/constant/countryData";
import axios from "axios";

const CustomInputPhone = ({ label, value, setValue, code, setCode }) => {
	const defaultCountry = {
		name: "India",
		flag: "🇮🇳",
		code: "IN",
		dial_code: "+91",
	};

	const [countryCode, setCountryCode] = useState(defaultCountry?.dial_code);

	const selectRef = useRef(null);
	// const [countryData, setCountryData] = useState([]);

	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleCountryCodeChange = (event) => {
		setCode(event.target.value);
	};

	const handlePhoneNumberChange = (event) => {
		setValue(event.target.value);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		// Perform any necessary actions with the phone number and country code
	};

	return (
		<Container>
			<Label>{label}</Label>

			<Div>
				<SelectContainer>
					<CustomLabelDiv>
						<CustomLabel>{code} ▾</CustomLabel>
					</CustomLabelDiv>
					<Select
						name="custom-select"
						ref={selectRef}
						id="custom-select"
						onChange={(e) => {
							handleCountryCodeChange(e);
						}}
						// value={code}
						className={code === "" ? "" : "selected"}
					>
						{countryData.map((item, i) => (
							<option value={item.dial_code} key={i}>
								{item.name} ({item.dial_code} {item.flag})
							</option>
						))}
					</Select>
				</SelectContainer>
				<PhoneNumberInput
					type="number"
					placeholder="Enter phone number"
					value={value}
					onChange={handlePhoneNumberChange}
				/>
			</Div>
		</Container>
	);
};

export default CustomInputPhone;

const Container = styled.div``;
const Label = styled.label`
	color: #6b6b6b;
	font-size: 15px;
	display: block;
	margin-bottom: 5px;
`;
const Div = styled.div`
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	height: 48px;
	transition: border-color 0.3s;
	display: flex;
	align-items: center;

	&:focus-within {
		border-color: #4582f7;
	}
`;
const CountryCodeSelect = styled.select`
	padding: 8px;
	border-radius: 4px;
`;
const Option = styled.option`
	width: 500px;
	background-color: red;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;
const PhoneNumberInput = styled.input`
	height: 100%;
	border-radius: 4px;
	width: 100%;
	color: #494a4a;
	font-size: 16px;
	padding-left: 8px;

	&::placeholder {
		color: #adadad;
	}
`;

const SubmitButton = styled.button`
	margin-top: 8px;
	padding: 8px 16px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

//

const SelectContainer = styled.div`
	height: 40px;
	position: relative;
	width: 100px;

	@media all and (max-width: 768px) {
		width: 120px;
	}
`;

const CustomLabelDiv = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
`;

const CustomLabel = styled.p`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	color: #000;
	border-right: 1px solid rgb(217, 217, 217);
`;

const CustomSelect = styled.label`
	background-color: #8561c6;
	position: absolute;
	bottom: 0;
	right: 0;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const Select = styled.select`
	height: 40px;
	width: 100%;
	padding: 10px;
	/* border: 1px solid #cccccc; */
	border-radius: 8px;
	/* appearance: none; */
	/* background-color: transparent; */
	cursor: pointer;
	color: #767b85;
	font-size: 15px;
	font-family: "PTSans-Regular";
	opacity: 0;

	&.selected {
		color: #000;
	}

	&::placeholder {
		color: #767b85;
		font-family: "PTSans-Regular";
	}
`;
const ArrowLabel = styled.label`
	cursor: pointer;

	position: absolute;
	top: 13px;
	right: 10px;
	background-color: red;
`;
const ArrowIcon = styled.img`
	width: 14px;
	height: 14px;
	/* position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	
	border-top: 2px solid #000000;
	border-right: 2px solid #000000;
	transition: transform 0.3s ease-in-out;
	pointer-events: none;
	transform-origin: center;
	${({ open }) => open && "transform: translateY(-50%) rotate(180deg);"} */
`;
