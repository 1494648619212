import React from "react";
import styled from "styled-components";

function NotFound404() {
	return (
		<MainContainer>
			<div className="wrapper">
				<Container>
					<Image
						src={require("./../../../assets/images/404-Image.png")}
					/>
					<Title>404 Page not Found</Title>
					<Description>
						You seems to have clicked on a broken link or entered a
						URL that doesn't exist.
					</Description>
				</Container>
			</div>
		</MainContainer>
	);
}

export default NotFound404;

const MainContainer = styled.div``;

const Container = styled.div`
	padding: 30px 0;
	min-height: calc(100vh - 100px);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	flex-direction: column;
`;
const Image = styled.img`
	margin-bottom: 10px;
	height: 350px;
	width: 350px;
	object-fit: cover;

	@media all and (max-width: 768px) {
		height: 260px;
		width: 260px;
	}
	@media all and (max-width: 480px) {
		height: 280px;
		width: 280px;
	}
`;
const Title = styled.h3`
	color: #2d2d2d;
	font-size: 32px;
	font-family: "DMSans-Medium";
	margin-bottom: 10px;

	@media all and (max-width: 1024px) {
		font-size: 28px;
	}
	@media all and (max-width: 480px) {
		font-size: 24px;
	}
`;
const Description = styled.h5`
	width: 40%;
	color: #7e7e7e;
	font-size: 16px;
	text-align: center;
	@media all and (max-width: 768px) {
		font-size: 15px;
		width: 60%;
	}

	@media all and (max-width: 480px) {
		width: 80%;
		font-size: 14px;
	}
`;
