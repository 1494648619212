import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SecondaryHeading from "../../includes/heading/SecondaryHeading";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//aos
import AOS from "aos";
import "aos/dist/aos.css";

function HomeReview() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	const reviews = [
		{
			id: 1,
			name: "Mr. Arjun Govind",
			date: "Assistant Professor-HR",
			review: "I'm thrilled to share my transformative experience with Goal Finder—an extraordinary psychometric tool that reshaped my career planning. Its Personality Assessment feature revealed self-awareness and diverse career options. Analyzing aspects like Introversion, Sensing, Thinking, and Perceiving, it illuminated my personality, guiding my ideal career path.",
			image: require("./../../../assets/images/arjun-1.png"),
		},
		{
			id: 2,
			name: "Dr. Bindhu Ann Thomas",
			date: "Director, Kochi Business School",
			review: "It’s my pleasure to use GoalFinder, a powerful tool for people to identify strengths and areas for improvement. As the Director of Kochi Business School, I highly recommend it to my students. I believe that it will empower them to make better decisions about their future and to choose the right path.",
			image: require("./../../../assets/images/bindhu-1.png"),
		},
		{
			id: 3,
			name: "Dimple Prabhoth",
			date: "Parent & Teacher, UAE",
			review: "As parents, We were unsure how to assist our child in picking a career. A friend helped us to learn about goalfinder. The test and mentor's discussion helped our child choose a career considering both abilities and interests. Grateful to Team GoalFinder for invaluable support in shaping our child's path.",
			image: require("./../../../assets/images/dimple-1.png"),
		},
		{
			id: 4,
			name: "Gokul Krishna",
			date: "Student, Grade 11",
			review: "As a Class 11 student, GoalFinder has truly transformed my journey. It has reshaped my career goals, providing a clarity I wouldn't have had otherwise. I started exploring universities much earlier. Without GoalFinder, I might have made the wrong career choice. GoalFinder has been my guiding light.",
			image: require("./../../../assets/images/gokul-2.png"),
		},
	];

	const reviewSettings = {
		dots: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 3,
		arrows: false,
		focusOnSelect: true,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 1500,
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	// slick
	let slider = useRef();

	const next = () => {
		slider.slickNext();
	};
	const previous = () => {
		slider.slickPrev();
	};

	return (
		<MainContainer
		// data-aos="fade-up"
		>
			<div className="wrapper">
				<Container>
					<Top>
						<Left>
							<SecondaryHeading
								value="Reviews"
								lineColor="#D4D4D4"
							/>
							<PrimaryHeading value="Sound Fro Happy" />
							<PrimaryHeading
								value="Users & Students"
								color="#4582f7"
							/>
						</Left>
						<Right>
							<LeftArrow onClick={previous}>
								<Arrow
									translateX="translateX(-2px)"
									alt="icon"
									src={
										require("./../../../assets/icons/blue-arrow.svg")
											.default
									}
									className="left-arrow"
								/>
							</LeftArrow>
							<RightArrow onClick={next}>
								<Arrow
									translateX="translateX(2px)"
									alt="icon"
									src={
										require("./../../../assets/icons/white-arrow.svg")
											.default
									}
								/>
							</RightArrow>
						</Right>
					</Top>
					<Bottom className="review-slick">
						<StyledSlider
							{...reviewSettings}
							ref={(c) => {
								slider = c;
							}}
						>
							{reviews.map((item, i) => (
								<div className="all" key={i}>
									<ReviewCard
									// data-aos="zoom-in-up"
									// key={i}
									>
										<div>
											<Icon
												src={
													require("./../../../assets/icons/review-slash.svg")
														.default
												}
												alt="icon"
											/>
											<Paragraph>{item.review}</Paragraph>
										</div>
										<UserDetails>
											<UserLeft>
												<Profile
													src={item.image}
													alt="user"
												/>
											</UserLeft>
											<UserRight>
												<Name>{item.name}</Name>
												<Date>{item.date}</Date>
											</UserRight>
										</UserDetails>
									</ReviewCard>
								</div>
							))}
						</StyledSlider>
					</Bottom>
				</Container>
			</div>
		</MainContainer>
	);
}

export default HomeReview;

const MainContainer = styled.div`
	background-color: #edf2fb;
	padding: 60px 0 40px;
	margin-top: 380px;

	@media all and (max-width: 1280px) {
		margin-top: 300px;
	}
	@media all and (max-width: 1024px) {
		margin-top: 350px;
	}
	@media all and (max-width: 640px) {
		margin-top: 450px;
		padding: 60px 0 0px;
	}

	@media all and (max-width: 480px) {
		margin-top: 300px;
	}
`;
const Container = styled.div`
	padding: 10px;
`;
const Top = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 40px;
	@media all and (max-width: 1024px) {
		margin-bottom: 30px;
	}
`;
const Left = styled.div``;
const Right = styled.div`
	display: flex;
	align-items: center;
`;

const Arrow = styled.img`
	height: 24px;
	width: 24px;
	display: inline-block;
	transition: 0.3s;
`;
const LeftArrow = styled.div`
	height: 40px;
	width: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	cursor: pointer;
	border: 1px solid #e9e8eb;

	:hover {
		border: 1px solid #3182ff;
	}
`;
const RightArrow = styled(LeftArrow)`
	background-color: #3182ff;
	margin-left: 20px;
	border: 1px solid #3182ff;

	:hover {
		border: 1px solid #fff;
	}
`;
const Bottom = styled.div`
	padding: 30px 0;
`;
const Icon = styled.img`
	height: 20px;
	width: 20px;
`;
const Paragraph = styled.p`
	color: #747474;
	font-size: 15px;
	transition: 0.3s;
	padding: 10px 0;
`;
const UserDetails = styled.div`
	border-top: 1px solid #d4d4d4;
	display: flex;
	align-items: center;
	transition: 0.3s;
	padding-top: 10px;
`;
const UserLeft = styled.div`
	margin-right: 15px;
`;
const Profile = styled.img`
	height: 50px;
	width: 50px;
	display: inline-block;
	object-fit: cover;
	border-radius: 50%;
	background-color: #fff;
`;
const UserRight = styled.div``;
const Name = styled.h4`
	color: #0a0a0a;
	font-size: 16px;
	transition: 0.3s;
	font-family: "DMSans-Bold";
`;
const Date = styled.h6`
	color: #747474;
	transition: 0.3s;
	font-size: 14px;
`;

const ReviewCard = styled.div`
	background-color: #fff;
	padding: 20px;
	border-radius: 6px;
	transition: 0.3s;
	width: 95% !important;
	min-height: 360px;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media all and (max-width: 640px) {
		width: 99% !important;
	}

	&:hover {
		transition: 0.3s;
		/* transform: translateY(-10px); */
		background-color: #01112d;
		/* padding: 30px 20px; */
		/* transform: translateY(-10px); */
		padding: 15px 20px;
		/* transition-delay: 0.03s; */
		transition-timing-function: ease;
		${Paragraph} {
			color: #eeeeee;
			transition: 0.3s;
			/* padding-top: 18px; */
		}
		${Name} {
			color: #eeeeee;
			transition: 0.3s;
		}
		${UserDetails} {
			border-top: 1px solid #233047;
			transition: 0.3s;
		}
	}
`;
const StyledSlider = styled(Slider)`
	/* Set the card container's fixed height */
	.slick-slide {
		/* display: flex;  */
	}

	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		height: inherit !important;
	}
`;
