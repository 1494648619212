import React from "react";
import styled from "styled-components";

function CustomInputText({
	label,
	placeholder,
	type = "text",
	value,
	setValue,
}) {
	const handleChange = (e) => {
		setValue(e.target.value);
	};
	return (
		<Container>
			<Label>{label}</Label>
			<Input
				placeholder={placeholder}
				type={type}
				value={value}
				onChange={handleChange}
			/>
		</Container>
	);
}

export default CustomInputText;

const Container = styled.div``;
const Label = styled.label`
	color: #6b6b6b;
	font-size: 15px;
	display: block;
	margin-bottom: 5px;
`;
const Input = styled.input`
	height: 48px;
	border: 1px solid #d9d9d9;
	font-size: 16px;
	border-radius: 8px;
	padding-left: 12px;
	color: #494a4a;
	outline: none;
	width: 100%;
	transition: border-color 0.3s;

	&::placeholder {
		color: #adadad;
	}

	&:focus {
		border-color: #4582f7;
	}
`;
