import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

//pages
import "./App.css";
import Header from "./components/screens/header/Header";
import Home from "./components/screens/home/Home";
import Footer from "./components/screens/footer/Footer";
import AboutUs from "./components/screens/about/AboutUs";
import OurTeams from "./components/screens/team/OurTeams";
import TeamSinglePage from "./components/screens/team/TeamSinglePage";
import ContactUs from "./components/screens/contact-us/ContactUs";
import Registration from "./components/screens/register/Registration";
import StudentRegistration from "./components/screens/register/StudentRegistration";
import ProfessionalRegistration from "./components/screens/register/ProfessionalRegistration";
import NotFound404 from "./components/screens/error-pages/NotFound404";

function App() {
	return (
		<>
			<Router>
				<Header />
				<Routes>
					{/* <Route
					exact
					path="/"
					element={<Navigate to="/login" replace />}
				/> */}
					{/* <Route path="/login" element={<Login />} /> */}
					<Route path="/" element={<Home />} />
					<Route path="about/" element={<AboutUs />} />
					<Route path="our-teams/" element={<OurTeams />} />
					<Route
						path="our-team/:username/"
						element={<TeamSinglePage />}
					/>
					<Route path="contact-us/" element={<ContactUs />} />
					<Route path="register/" element={<Registration />} />
					<Route
						path="student-register/"
						element={<StudentRegistration />}
					/>
					<Route
						path="professional-register/"
						element={<ProfessionalRegistration />}
					/>

					<Route exact path="*" element={<NotFound404 />} />
				</Routes>
				<Footer />
			</Router>
		</>
	);
}

export default App;
