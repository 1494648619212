import React from "react";
import styled from "styled-components";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

function TeamSinglePage() {
	return (
		<MainContainer>
			<EmptyDiv />
			<div className="wrapper">
				<Container>
					<Line />
					<PrimaryHeading
						value="Voice of Our Team Member"
						color="#0A0A0A"
					/>
					<Body>
						<Left>
							<P>
								The goalfinder team strives to provide
								exceptional service to its clients at all times.
								Our services are tailored to meet your specific
								needs. We guarantee that we can fully meet your
								self improvement needs through proper self
								evaluation and career crafting
							</P>
							<P>
								Planning for higher education as well as
								developing a career plan are essential parts of
								any student's education. Success in life is a
								result of choosing the right career, which in
								turn leads to happiness in one's life. In many
								instances, the decision to pursue a career and
								study path is not a conscious and scientific
								one. A number of factors may contribute to this;
								for example, a lack of awareness, peer group
								influences, emotional pressure, and social
								pressure. Our goal is to assist children in
								identifying their true potential by mapping
								their skills, interests, and personality traits.
								We developed this tool at Goal Finder as a means
								of helping them identify their true potential.
								By taking this test, a child will be able to
								identify those areas in which he has a high
								probability of success. Furthermore, it
								identifies moderate areas of interest as well as
								areas that should not be pursued.
							</P>
						</Left>
						<Right>
							<Card>
								<Image
									src={require("./../../../assets/images/person-2.jpg")}
									alt="members"
								/>
								<Name>Rachel Ghoster</Name>
								<Designation>Product Designer</Designation>
							</Card>
						</Right>
					</Body>
				</Container>
			</div>
		</MainContainer>
	);
}

export default TeamSinglePage;

const MainContainer = styled.div`
	/* padding: 40px 0; */
`;

const EmptyDiv = styled.div`
	background-color: #f5f8ff;
	position: absolute;
	top: 0;
	z-index: -1;
	right: 0;
	width: 0;
	height: 0;
	transition: width height 0.3s;
	animation: 0.8s in-out forwards;

	@keyframes in-out {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			height: 400px;
			width: 31%;
		}
	}

	@media all and (max-width: 1024px) {
		display: none;
	}
`;
const Container = styled.div`
	padding: 60px;
	@media all and (max-width: 1280px) {
		padding: 40px;
	}

	@media all and (max-width: 768px) {
		padding: 30px 0;
	}
`;

const Line = styled.div`
	background-color: #76b82a;
	width: 70px;
	height: 8px;
	border-radius: 4px;
	margin-bottom: 10px;
`;
const Body = styled.div`
	margin-top: 50px;
	display: flex;
	align-items: start;
	justify-content: space-between;
	@media all and (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;
const Left = styled.div`
	width: 70%;
	padding-right: 30px;
	border-right: 1px solid #d4d4d4;

	@media all and (max-width: 1280px) {
		width: 65%;
	}
	@media all and (max-width: 768px) {
		border: none;
		width: 100%;
		padding-right: 0px;
		margin-top: 30px;
	}
`;
const P = styled.p`
	color: #747474;
	font-size: 18px;
	font-family: "DMSans-Regular";
	margin-bottom: 30px;
	width: 95%;

	&:last-child {
		margin-bottom: 0;
	}

	@media all and (max-width: 768px) {
		font-size: 16px;
		width: 100%;
	}
	@media all and (max-width: 480px) {
		font-size: 15px;
	}
`;
const Right = styled.div`
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media all and (max-width: 1280px) {
		width: 35%;
	}
	@media all and (max-width: 768px) {
		width: 100%;
	}
`;

const Card = styled.div`
	/* padding: 30px 40px; */
	border-radius: 4px;
`;
const Image = styled.img`
	height: 230px;
	width: 230px;
	border-radius: 50%;
	display: inline-block;
	object-fit: cover;
	@media all and (max-width: 1024px) {
		height: 160px;
		width: 160px;
	}
	@media all and (max-width: 768px) {
		height: 180px;
		width: 180px;
	}
	@media all and (max-width: 480px) {
		height: 150px;
		width: 150px;
	}
	@media all and (max-width: 380px) {
		height: 120px;
		width: 120px;
	}
`;
const Name = styled.h5`
	text-align: center;
	color: #0a0a0a;
	font-size: 18px;
	margin-top: 5px;
`;
const Designation = styled.p`
	text-align: center;
	color: #747474;
	font-size: 14px;
`;
