import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import FAQ from "../home/FAQ";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//image
import bgImage from "./../../../assets/images/fade-logo.png";

function ContactUs() {
	const contactInformation = [
		{
			id: 1,
			icon: require("./../../../assets/icons/location.svg").default,
			label: "Address",
			value: "De Lead International, 119,RAG Business Center, AI Hilal Bank Building, AI Qusais Dubai UAE",
		},
		{
			id: 2,
			icon: require("./../../../assets/icons/call.svg").default,
			label: "Phone Number",
			value: "+91 9048 678 209",
		},
		{
			id: 3,
			icon: require("./../../../assets/icons/call.svg").default,
			label: "Phone Number",
			value: "+971 506814538",
		},
		{
			id: 4,
			icon: require("./../../../assets/icons/email.svg").default,
			label: "Email ID",
			value: "info@goalfinder.org",
		},
	];

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [isLoading, setLoading] = useState(false);

	//error
	const [isError, setError] = useState(false);
	const [errorField, setErrorField] = useState("");

	const submitContactForm = () => {
		// if (name == "" || name.length <= 0) {
		// 	setError(true);
		// 	setErrorField("name");
		// } else if (email == "" || email.length <= 0) {
		// 	setError(true);
		// 	setErrorField("email");
		// } else if (message == "" || message.length <= 0) {
		// 	setError(true);
		// 	setErrorField("message");
		// } else {
		// setLoading(true);
		axios
			.post("https://app.goalfinder.org/api/contact", {
				name,
				email,
				message,
			})
			.then((response) => {
				if (response.status == 200) {
					setName("");
					setEmail("");
					setMessage("");
					setLoading(false);
					toast.success(
						"Thank you! Your message has been successfully submitted. "
					);
				}
			})
			.catch((err) => {
				// toast.error("Oops, something went wrong");
				// setLoading(false);
				const errorObj = err.response.data.errors;
				const keys = Object.keys(errorObj);

				if (keys) {
					toast.error(errorObj[keys[0]][0]);
				} else {
					toast.error("Oops, something went wrong");
				}
				setLoading(false);
			});
		// }
	};

	function ContactCard({ item }) {
		return (
			<Card>
				<CardLeft>
					<IconDiv>
						<Icon src={item.icon} alt="icon" />
					</IconDiv>
				</CardLeft>
				<CardRight>
					<CardLabel>{item.label}</CardLabel>
					<CardValue>{item.value}</CardValue>
				</CardRight>
			</Card>
		);
	}

	return (
		<MainContainer>
			<EmptyDiv />
			<div className="wrapper">
				<Container>
					<Top>
						<Line />

						<HeadingDiv>
							<PrimaryHeading value={"Contact"} color="#0A0A0A" />
							<PrimaryHeading value={"Us"} color="#4582f7" />
						</HeadingDiv>
					</Top>
					<Body>
						<Left>
							<P>
								Feel free to contact us any time. we will get
								back to you as soon as we can!
							</P>
							<FormDiv>
								<InputContainer
									borderColor={
										isError && errorField === "name"
											? "red"
											: "#dfdfdf"
									}
								>
									<IconDiv>
										<Icon
											src={
												require("./../../../assets/icons/users.svg")
													.default
											}
											alt="icon"
										/>
									</IconDiv>
									<Input
										type="text"
										placeholder="Full Name"
										value={name}
										onChange={(e) => {
											setName(e.target.value);
											setError(false);
										}}
									/>
								</InputContainer>
								<InputContainer
									borderColor={
										isError && errorField === "email"
											? "red"
											: "#dfdfdf"
									}
								>
									<IconDiv>
										<Icon
											src={
												require("./../../../assets/icons/email.svg")
													.default
											}
											alt="icon"
										/>
									</IconDiv>
									<Input
										type="email"
										placeholder="sample@gmail.com"
										value={email}
										onChange={(e) => {
											setEmail(e.target.value);
											setError(false);
										}}
									/>
								</InputContainer>
								{/* <TextareaContainer> */}
								<TextArea
									placeholder="Message"
									value={message}
									onChange={(e) => {
										setMessage(e.target.value);
										setError(false);
									}}
									borderColor={
										isError && errorField === "message"
											? "red"
											: "#dfdfdf"
									}
								/>
								{/* </TextareaContainer> */}
								<SentButton
									className={isLoading ? "loading" : ""}
									disabled={isLoading}
									onClick={(e) => {
										e.preventDefault();
										submitContactForm();
									}}
								>
									Sent
								</SentButton>
							</FormDiv>
						</Left>
						<Right>
							<RightHeading>Get In Touch</RightHeading>
							<CardContainer>
								{contactInformation.map((item) => (
									<ContactCard key={item.id} item={item} />
								))}
							</CardContainer>
						</Right>
					</Body>
				</Container>
				<FAQ />
			</div>
			<ToastContainer />
		</MainContainer>
	);
}

export default ContactUs;

const MainContainer = styled.div``;

const EmptyDiv = styled.div`
	background-color: #f5f8ff;
	position: absolute;
	top: 0;
	z-index: -1;
	right: 0;
	width: 0;
	height: 0;
	transition: width height 0.3s;
	animation: 0.8s in-outs forwards;

	@keyframes in-outs {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			height: 789px;
			width: 31%;
		}
	}

	@media all and (max-width: 1024px) {
		@keyframes in-outs {
			0% {
				width: 0;
				height: 0;
			}
			100% {
				height: 400px;
				width: 40%;
			}
		}
	}

	@media all and (max-width: 768px) {
		@keyframes in-outs {
			0% {
				width: 0;
				height: 0;
			}
			100% {
				height: 180px;
				width: 50%;
			}
		}
	}
`;
const Container = styled.div`
	padding: 60px 0;

	@media all and (max-width: 768px) {
		padding: 30px 0;
	}
`;
const Top = styled.div`
	display: flex;
	flex-direction: column;
`;

const Line = styled.div`
	background-color: #76b82a;
	width: 70px;
	height: 8px;
	border-radius: 4px;
	margin-bottom: 5px;
`;
const HeadingDiv = styled.div`
	display: flex;
	align-items: center;

	h2:nth-child(1) {
		margin-right: 10px;
	}
`;

const Body = styled.div`
	margin: 30px 0;
	display: flex;
	justify-content: space-between;

	@media all and (max-width: 768px) {
		flex-direction: column;
	}

	/* align-items: end; */
`;
const Left = styled.div`
	width: 48%;
	@media all and (max-width: 768px) {
		width: 100%;
	}
`;
const P = styled.p`
	color: #747474;
	font-size: 16px;
	width: 80%;
	@media all and (max-width: 480px) {
		width: 100%;
		font-size: 15px;
	}
`;
const FormDiv = styled.div`
	margin-top: 20px;
`;
const InputContainer = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid #dfdfdf;
	padding-left: 12px;
	border-radius: 8px;
	background-color: #f5f8ff;
	transition: border-color 0.3s;
	height: 48px;
	margin-bottom: 15px;
	border-color: ${(props) =>
		props.borderColor ? props.borderColor : "#dfdfdf"};

	&:focus-within {
		border-color: #007bff;
		/* border-color: ${(props) =>
			props.borderColor ? props.borderColor : "#007bff"}; */
	}
	&:last-child {
		margin-bottom: 0px;
	}
`;

const Input = styled.input`
	flex: 1;
	border: none;
	outline: none;
	font-size: 15px;
`;

const IconDiv = styled.div`
	margin-right: 0.5rem;
	height: 20px;
	width: 20px;
`;

const Icon = styled.img`
	height: 100%;
	width: 100%;
`;
const TextareaContainer = styled.div`
	display: flex;
	/* align-items: center; */
	border: 1px solid #dfdfdf;
	padding-left: 12px;
	border-radius: 8px;
	background-color: #f5f8ff;
	transition: border-color 0.3s;
	min-height: 100px;
	/* margin-bottom: 15px; */

	&:focus-within {
		border-color: #007bff;
	}
`;
const TextArea = styled.textarea`
	flex: 1;
	outline: none;
	font-size: 15px;
	font-family: "DMSans-Regular";
	min-height: 150px;
	border: 1px solid #dfdfdf;
	padding: 12px;
	border-radius: 8px;
	background-color: #f5f8ff;
	width: 100%;
	transition: border-color 0.3s;
	resize: vertical;
	border-color: ${(props) =>
		props.borderColor ? props.borderColor : "#dfdfdf"};

	&:focus {
		border-color: #007bff;
	}
`;

const SentButton = styled.button`
	border: 1px solid #4582f7;
	height: 48px;
	width: 100%;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 16px;
	cursor: pointer;
	background: #4582f7;
	color: #fff;
	margin-top: 15px;

	&.loading {
		background: #4582f766;
		border-color: #4582f766;
		cursor: not-allowed;
	}
`;

const Right = styled.div`
	width: 48%;
	background-color: #01112d;
	border-radius: 8px;
	background-image: url(${bgImage});
	background-size: 35% 50%;
	background-repeat: no-repeat;
	background-position: right bottom;
	padding: 60px;

	@media all and (max-width: 1280px) {
		background-size: 40% 50%;
	}

	@media all and (max-width: 1024px) {
		background-size: 35% 35%;
		background-position: 95% 95%;
		padding: 30px;
	}
	@media all and (max-width: 768px) {
		width: 100%;
		margin-top: 60px;
		background-size: 30% 55%;
	}

	@media all and (max-width: 640px) {
		background-size: 30% 45%;
	}

	@media all and (max-width: 480px) {
		background-size: 30% 35%;
	}
`;
const RightHeading = styled.h4`
	color: #eeeeee;
	font-size: 28px;

	@media all and (max-width: 1024px) {
		font-size: 24px;
	}
`;
const CardContainer = styled.div`
	margin-top: 20px;
`;
const Card = styled.div`
	display: flex;
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0px;
	}
`;
const CardLeft = styled.div`
	margin-right: 5px;
`;
const CardRight = styled.div`
	width: 100%;
`;
const CardLabel = styled.h4`
	color: #adadad;
	font-size: 16px;
	@media all and (max-width: 1024px) {
		font-size: 15px;
	}
	@media all and (max-width: 480px) {
		font-size: 14px;
	}
`;
const CardValue = styled.p`
	color: #eeeeee;
	font-size: 18px;
	@media all and (max-width: 1024px) {
		font-size: 16px;
	}

	@media all and (max-width: 768px) {
		width: 70%;
	}

	@media all and (max-width: 640px) {
		width: 90%;
	}
	@media all and (max-width: 480px) {
		width: 100%;
		font-size: 15px;
	}
`;
