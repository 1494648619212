import React from "react";
import styled from "styled-components";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

//images
import bgImage from "./../../../assets/images/fade-logo.png";
import { Link } from "react-router-dom";

function Registration() {
	return (
		<MainContainer>
			<EmptyDiv />
			<div className="wrapper">
				<Container>
					<Top>
						<Line />

						<PrimaryHeading
							value={"Select Your Registration Area"}
							color="#0A0A0A"
						/>
						<TopParagraph>
							Discover your path to success: Pick your
							registration area – student or professional – and
							let GoalFinder guide you toward success and growth.
						</TopParagraph>
					</Top>
					<CardContainer>
						<Card>
							<CardTop>
								<H4>Are You Student?</H4>
								<P>
									Get ready to thrive as a student and
									Register now! We'll help you chart a path
									towards academic success and personal
									growth.
								</P>
							</CardTop>
							<CardBottom>
								<BottomRight to="/student-register">
									<RegisterText>Register Now</RegisterText>
									<ArrowDiv>
										<Arrow
											src={
												require("./../../../assets/icons/blue-arrow.svg")
													.default
											}
											alt="icon"
										/>
									</ArrowDiv>
								</BottomRight>
							</CardBottom>
						</Card>

						<Card>
							<CardTop>
								<H4>Are You Professionals?</H4>
								<P>
									Elevate your career with GoalFinde! Gain
									personalized insights and guidance for
									achieving success in your professional
									endeavors.
								</P>
							</CardTop>
							<CardBottom>
								<BottomRight to="/professional-register">
									<RegisterText>Register Now</RegisterText>
									<ArrowDiv>
										<Arrow
											src={
												require("./../../../assets/icons/blue-arrow.svg")
													.default
											}
											alt="icon"
										/>
									</ArrowDiv>
								</BottomRight>
							</CardBottom>
						</Card>
					</CardContainer>
				</Container>
			</div>
		</MainContainer>
	);
}

export default Registration;

const MainContainer = styled.div``;

const EmptyDiv = styled.div`
	background-color: #f5f8ff;
	position: absolute;
	top: 0;
	z-index: -1;
	right: 0;
	width: 0;
	height: 0;
	transition: width height 0.3s;
	animation: 0.8s in-out forwards;

	@keyframes in-out {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			height: 300px;
			width: 25%;
		}
	}

	@media all and (max-width: 1024px) {
		display: none;
	}
`;
const Container = styled.div`
	padding: 60px 0;
`;

const Top = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	border-bottom: 1px solid #d4d4d4;
	padding-bottom: 40px;

	@media all and (max-width: 380px) {
		text-align: center;
	}
`;

const Line = styled.div`
	background-color: #76b82a;
	width: 70px;
	height: 8px;
	border-radius: 4px;
	margin-bottom: 15px;
`;
const HeadingDiv = styled.div`
	display: flex;
	align-items: center;
	h2:nth-child(1) {
		margin-right: 10px;
	}
`;
const TopParagraph = styled.div`
	color: #747474;
	font-size: 18px;
	text-align: center;
	width: 60%;
	margin-top: 15px;
	@media all and (max-width: 1024px) {
		width: 80%;
	}

	@media all and (max-width: 768px) {
		font-size: 16px;
	}
	@media all and (max-width: 480px) {
		font-size: 15px;
		width: 100%;
	}
`;
const CardContainer = styled.div`
	padding: 60px;
	display: flex;
	align-items: center;
	gap: 30px;
	justify-content: space-between;

	@media all and (max-width: 1024px) {
		padding: 60px 0;
	}
	@media all and (max-width: 768px) {
		flex-direction: column;
	}
`;
const Card = styled.div`
	padding: 20px;
	background-color: #4582f7;
	border-radius: 8px;
	background-image: url(${bgImage});
	background-size: 250px 250px;
	background-repeat: no-repeat;
	background-position: -20px 90px;
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media all and (max-width: 1024px) {
		height: 260px;
		background-size: 170px 170px;
	}
`;
const CardTop = styled.div``;
const H4 = styled.h4`
	color: #ffffff;
	font-size: 30px;
	font-family: "DMSans-medium";

	@media all and (max-width: 1024px) {
		font-size: 26px;
	}

	@media all and (max-width: 768px) {
		font-size: 24px;
	}
	@media all and (max-width: 480px) {
		font-size: 22px;
	}
`;
const P = styled.p`
	color: #eeeeee;
	font-size: 16px;
	width: 75%;
	font-weight: 400;
	@media all and (max-width: 1024px) {
		width: 100%;
	}
	@media all and (max-width: 768px) {
		width: 75%;
		font-size: 15px;
	}
`;
const CardBottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const Arrow = styled.img`
	height: 24px;
	width: 24px;
	transform: rotate(180deg);
	transition: transform 0.3s;
`;

const BottomRight = styled(Link)`
	width: max-content;
	display: flex;
	align-items: center;

	&:hover {
		cursor: pointer;
		${Arrow} {
			transform: rotate(150deg);
		}
	}
`;
const RegisterText = styled.h5`
	font-size: 18px;
	font-family: "DMSans-medium";
	color: #fff;
`;
const ArrowDiv = styled.div`
	background-color: #fff;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
`;
