import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Footer() {
	const handleScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	return (
		<MainContainer>
			<div className="wrapper">
				<Container>
					<Left>
						<H1>
							<LogoLink>
								<LogoImage
									src={require("./../../../assets/images/logo.png")}
									alt="logo"
								/>
							</LogoLink>
						</H1>
						<SocialMediaDiv>
							<IconDiv>
								<Icon
									src={
										require("./../../../assets/icons/instagram.svg")
											.default
									}
									alt="icon"
								/>
							</IconDiv>
							<IconDiv>
								<Icon
									src={
										require("./../../../assets/icons/facebook.svg")
											.default
									}
									alt="icon"
								/>
							</IconDiv>
							<IconDiv>
								<Icon
									src={
										require("./../../../assets/icons/twitter.svg")
											.default
									}
									alt="icon"
								/>
							</IconDiv>
							<IconDiv>
								<Icon
									src={
										require("./../../../assets/icons/linkedin.svg")
											.default
									}
									alt="icon"
								/>
							</IconDiv>
							<IconDiv>
								<Icon
									src={
										require("./../../../assets/icons/youtube.svg")
											.default
									}
									alt="icon"
								/>
							</IconDiv>
						</SocialMediaDiv>
					</Left>
					<Middle>
						<MiddleDiv>
							<MiddleHeading>Company</MiddleHeading>
							<MiddileLink onClick={handleScrollToTop} to="/">
								Home
							</MiddileLink>
							<MiddileLink
								onClick={handleScrollToTop}
								to="/about"
							>
								About Us
							</MiddileLink>
							{/* <MiddileLink
							onClick={handleScrollToTop}
							
							to="/our-teams">Our Team</MiddileLink> */}
							<MiddileLink
								onClick={handleScrollToTop}
								to="/contact-us"
							>
								Contact Us
							</MiddileLink>
						</MiddleDiv>
						<MiddleDiv>
							<MiddleHeading>Support</MiddleHeading>
							<MiddileLink to="">24/7 Support</MiddileLink>
							<MiddileLink to="">FAQs</MiddileLink>
							<MiddileLink to="">Free Tool</MiddileLink>
							<MiddileLink to="">Our Experts</MiddileLink>
						</MiddleDiv>
						<MiddleDiv>
							<MiddleHeading>Resources</MiddleHeading>
							<MiddileLink to="">Blog</MiddileLink>
							<MiddileLink to="">Media </MiddileLink>
							<MiddileLink to="">User Login </MiddileLink>
						</MiddleDiv>
					</Middle>
					<Right>
						<LeftArrow onClick={handleScrollToTop}>
							<Arrow
								alt="icon"
								src={
									require("./../../../assets/icons/black-arrow.svg")
										.default
								}
							/>
						</LeftArrow>
					</Right>
				</Container>
				<CopyRightDiv>
					<CopyRight>
						© 2022 Designed by arshal.in All rights reserved
					</CopyRight>
				</CopyRightDiv>
			</div>
		</MainContainer>
	);
}

export default Footer;

const MainContainer = styled.div`
	background-color: #01112d;
	/* padding: 40px 0; */
`;
const Container = styled.div`
	display: flex;
	align-items: start;
	justify-content: space-between;
	padding: 60px 0;
	border-bottom: 1px solid #3a4250;
	@media all and (max-width: 768px) {
		flex-wrap: wrap;
	}
`;
const Left = styled.div`
	width: 25%;
	@media all and (max-width: 1024px) {
		width: 20%;
	}
	@media all and (max-width: 768px) {
		width: 49%;
	}
`;
const H1 = styled.h1``;
const LogoLink = styled.a``;
const LogoImage = styled.img`
	display: block;
	height: 60px;
	width: 180px;
	@media all and (max-width: 1024px) {
		height: 50px;
		width: 140px;
	}
`;
const SocialMediaDiv = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 10px;
	margin-top: 20px;
`;
const IconDiv = styled.div`
	height: 20px;
	width: 20px;
	cursor: pointer;
`;
const Icon = styled.img`
	display: inline-block;
	height: 20px;
	width: 20px;
`;

const Middle = styled.div`
	display: flex;
	align-items: start;
	justify-content: space-between;
	width: 45%;
	@media all and (max-width: 1024px) {
		width: 50%;
	}
	@media all and (max-width: 768px) {
		order: 3;
		width: 100%;
		margin-top: 30px;
	}
`;
const MiddleDiv = styled.div``;
const MiddleHeading = styled.h3`
	color: #fff;
	font-size: 16px;
	margin-bottom: 15px;

	@media all and (max-width: 380px) {
		font-size: 15px;
	}
`;
const MiddileLink = styled(Link)`
	color: #aaaaaa;
	font-size: 15px;
	display: block;
	margin-bottom: 5px;
	:last-child {
		margin-bottom: 0px;
	}

	@media all and (max-width: 380px) {
		font-size: 14px;
	}
`;

const Right = styled.div`
	width: 25%;
	display: flex;
	justify-content: flex-end;
	@media all and (max-width: 1024px) {
		width: 20%;
	}
	@media all and (max-width: 768px) {
		order: 2;
		width: 49%;
	}
`;

const LeftArrow = styled.div`
	height: 50px;
	width: 50px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	cursor: pointer;
	border: 1px solid #e9e8eb;

	:hover {
		border: 1px solid #01112d;
	}
`;

const Arrow = styled.img`
	height: 24px;
	width: 24px;
	display: inline-block;
	transition: 0.3s;
`;
const CopyRightDiv = styled.div`
	padding: 15px 0;
`;
const CopyRight = styled.p`
	color: #aaaaaa;
	font-size: 14px;
	text-align: center;
`;
