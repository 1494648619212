import React, { useEffect } from "react";
import styled from "styled-components";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

//image
import bgImage from "./../../../assets/images/service-bg-1.png";
import lapImage from "./../../../assets/images/lap-dashbord.png";

//aos
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function PlatformView() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	const couterValue = [
		{
			id: 1,
			count: "2+",
			text: "Year of Experiance",
			description:
				"Vestibulum tempus imperdiet sem ac porttitor. Vivamus pulvinar ",
		},
		{
			id: 2,
			count: "1,800+",
			text: "Psychometric Test",
			description:
				"Vestibulum tempus imperdiet sem ac porttitor. Vivamus pulvinar ",
		},
		{
			id: 3,
			count: "800+",
			text: "Counselling",
			description:
				"Vestibulum tempus imperdiet sem ac porttitor. Vivamus pulvinar ",
		},
	];

	return (
		<MainContainer>
			<div className="wrapper">
				<Container>
					<Top>
						<HeadingContainer>
							<div className="one">
								<PrimaryHeading
									value={"Introducing the Best"}
									color="#fff"
								/>
							</div>
							{/* <HeadingDiv> */}
							<div className="two">
								<PrimaryHeading
									value={"Counselling"}
									color="#fff"
								/>
							</div>
							{/* </HeadingDiv> */}
							<div className="three">
								<PrimaryHeading
									value={"Platform Dashboard"}
									color="#4582f7"
								/>
							</div>
						</HeadingContainer>
						<Paragraph>
							De’ Lead International is a training consultancy
							company formed with a vision to contribute to
							society by identifying the innate talents of our
							younger generations and
						</Paragraph>
						<PrimaryButton to="register/">
							Register Now
						</PrimaryButton>
					</Top>

					<ImageContainer
					// data-aos="zoom-out"
					>
						<ImageView>
							<Image src={lapImage} alt="image" />
						</ImageView>
					</ImageContainer>

					<CounterContainer>
						{couterValue.map((item, i) => (
							<React.Fragment key={i}>
								<CounterDiv>
									<Count>{item.count}</Count>
									<CountText>{item.text}</CountText>
									{/* <CouterDescription>
										{item.description}
									</CouterDescription> */}
								</CounterDiv>
								<Hr />
							</React.Fragment>
						))}
					</CounterContainer>
				</Container>
			</div>
		</MainContainer>
	);
}

export default PlatformView;

const MainContainer = styled.div`
	.wrapper {
		background-color: #01112d;
		padding: 80px 0;
		height: 800px;
		border-radius: 12px;
		width: 95%;

		background-image: url(${bgImage});
		background-repeat: no-repeat;
		background-size: 75% 90%;

		@media all and (max-width: 768px) {
			height: 730px;
		}
		@media all and (max-width: 640px) {
			height: 650px;
		}
		@media all and (max-width: 640px) {
			height: 600px;
		}
	}
`;
const Container = styled.div`
	/* padding: 40px; */
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const HeadingContainer = styled.div`
	div.one {
		text-align: center;
	}
	div.two {
		display: inline-block;
		text-align: center;
		margin-right: 8px;
		@media all and (max-width: 380px) {
			display: block;
		}
	}
	div.three {
		display: inline-block;
		text-align: center;
	}
`;
const HeadingDiv = styled.div`
	display: flex;
	align-items: center;
	h2:nth-child(1) {
		margin-right: 10px;
	}
`;
const Paragraph = styled.p`
	color: #adadad;
	font-size: 16px;
	width: 50%;
	text-align: center;
	margin: 30px 0;

	@media all and (max-width: 768px) {
		width: 80%;
	}

	@media all and (max-width: 480px) {
		font-size: 15px;
	}
`;
const PrimaryButton = styled(Link)`
	color: #fff;
	border: 1px solid #4582f7;
	background-color: #4582f7;
	border-radius: 6px;
	height: 50px;
	padding: 0 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 16px;
	margin-right: 15px;
`;
const ImageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
`;
const ImageView = styled.div`
	/* height: 100%;
	width: 70%; */
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70%;

	/* @media all and (max-width: 1024px) {
		width: 90%;
	} */
	@media all and (max-width: 1024px) {
		width: 100%;
	}
`;
const Image = styled.img`
	display: inline-block;
	/* height: 100%; */
	/* width: 900px; */
	/* aspect-ratio: 16 / 9;
	width: 80%; */
	width: 100%;
	/* @media all and (max-width: 768px) {
		aspect-ratio: 16 / 12;
	} */
`;

const CounterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80%;
	margin: 0 auto;
	@media all and (max-width: 1024px) {
		width: 100%;
	}
	@media all and (max-width: 640px) {
		flex-wrap: wrap;
	}
`;
const CounterDiv = styled.div`
	padding: 40px 60px;

	@media all and (max-width: 1024px) {
		padding: 30px;
	}
	@media all and (max-width: 640px) {
		width: 48%;
	}
	@media all and (max-width: 480px) {
		padding: 10px;
	}
`;
const Count = styled.h3`
	color: #3283ff;
	font-size: 32px;
	font-family: "DMSans-Bold";
	text-align: center;
`;
const CountText = styled.h6`
	color: #0a0a0a;
	font-size: 15px;
	margin: 10px 0;
	text-align: center;
`;
const CouterDescription = styled.p`
	color: #747474;
	font-size: 13px;
	text-align: center;
`;
const Hr = styled.div`
	height: 55px;
	width: 2px;
	background-color: #7474744d;
	:last-child {
		display: none;
	}

	&:nth-child(4) {
		@media all and (max-width: 640px) {
			display: none;
		}
	}
`;
