import React from "react";
import styled from "styled-components";

function SecondaryHeading({ value, lineColor, left = false }) {
	return (
		<Container>
			<LineLeft lineColor={lineColor} className={left ? "left" : ""} />
			<SecondaryTitle className={left ? "left" : ""}>
				{value}
			</SecondaryTitle>
			<LineRight lineColor={lineColor} />
		</Container>
	);
}

export default SecondaryHeading;

const Container = styled.div`
	display: flex;
	align-items: center;
`;
const SecondaryTitle = styled.h4`
	color: rgba(116, 116, 116, 1);
	position: relative;
	font-size: 16px;
	margin-right: 10px;

	&.left {
		margin-left: 10px;
	}
`;

const LineRight = styled.div`
	width: 80px;
	height: 2px;
	margin-bottom: -2px;
	background-color: ${(props) =>
		props.lineColor ? props.lineColor : "rgba(212, 212, 212, 1)"};
`;

const LineLeft = styled.div`
	width: 0px;
	height: 0px;
	margin-bottom: -2px;
	background-color: ${(props) =>
		props.lineColor ? props.lineColor : "rgba(212, 212, 212, 1)"};

	&.left {
		height: 2px;
		width: 80px;
	}
`;
