import React from "react";
import styled from "styled-components";

function SaveButton({ onClick }) {
	return (
		<Button
			onClick={(e) => {
				e.preventDefault();
				onClick();
			}}
		>
			Submit
		</Button>
	);
}

export default SaveButton;

const Button = styled.button`
	border: 1px solid #4582f7;
	height: 48px;
	width: 30%;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 16px;
	cursor: pointer;
	background: #4582f7;
	color: #fff;
	font-size: 16px;
`;
