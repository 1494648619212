import React, { useState } from "react";
import styled from "styled-components";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";
import CustomInputText from "../../includes/input/CustomInputText";
import CustomInputRadio from "../../includes/input/CustomInputRadio";
import CustomInputDatePicker from "../../includes/input/CustomInputDatePicker";
import CustomInputPhone from "../../includes/input/CustomInputPhone";
import SaveButton from "../../includes/button/SaveButton";
import CancelButton from "../../includes/button/CancelButton";
import CustomSelect from "../../includes/input/CustomSelect";
import { useNavigate, useHistory } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ProfessionalRegistration() {
	const navigate = useNavigate();

	const formInstruction = [
		{
			id: 1,
			label: "Name of the Candidate",
			type: "text",
			placeholder: "Enter your name",
		},
		{
			id: 2,
			label: "Email id",
			type: "email",
			placeholder: "sample@gmail.com",
		},
	];

	const genderData = [
		{
			id: 1,
			text: "Male",
			value: "male",
		},
		{
			id: 2,
			text: "Female",
			value: "female",
		},
		{
			id: 3,
			text: "Other",
			value: "other",
		},
	];

	const qualifications = [
		{
			id: 5,
			text: "Graduation",
			value: "Graduation",
		},
		{
			id: 6,
			text: "Post-Graduation",
			value: "Post-Graduation",
		},
	];

	const defaultCountry = {
		name: "India",
		flag: "🇮🇳",
		code: "IN",
		dial_code: "+91",
	};

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setDob] = useState("");
	const [whatsAppCode, setWhatsAppCode] = useState(defaultCountry?.dial_code);
	const [whatsApp, setWhatsApp] = useState("");
	const [phoneCode, setPhoneCode] = useState(defaultCountry?.dial_code);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [education, setEducation] = useState("");
	const [otherEducation, setOtherEducation] = useState("");
	const [experiance, setExperiance] = useState("");

	const [isLoading, setLoading] = useState(false);

	const defaultValue = () => {
		setName("");
		setEmail("");
		setGender("");
		setDob("");
		setWhatsApp("");
		setPhoneNumber("");
		setEducation("");
		// setSchoolName("");
		setOtherEducation("");
		setPhoneCode(defaultCountry?.dial_code);
		setWhatsAppCode(defaultCountry?.dial_code);
		setExperiance("");
	};

	const submitRegisterForm = () => {
		setLoading(true);
		axios
			.post("https://app.goalfinder.org/api/register", {
				name,
				email,
				gender,
				dob: dob ? dob.toISOString().split("T")[0] : "",
				whatsapp_number: whatsAppCode + whatsApp,
				contact_number: phoneCode + phoneNumber,
				education: education == "other" ? otherEducation : education,
				type: "employee",
				experiance,
			})
			.then((response) => {
				if (response.status == 200) {
					defaultValue();
					setLoading(false);
					toast.success("Thank you! Registration Successful!");
				}
			})
			.catch((err) => {
				const errorObj = err.response.data.errors;
				const keys = Object.keys(errorObj);

				if (keys) {
					toast.error(errorObj[keys[0]][0]);
				} else {
					toast.error("Oops, something went wrong");
				}
				setLoading(false);
			});
	};

	return (
		<MainContainer>
			<EmptyDiv />
			<div className="wrapper">
				<Container>
					<Top>
						<Line />

						<PrimaryHeading
							value={"Professionals Registration Form"}
							color="#0A0A0A"
						/>
					</Top>
					<FormBody>
						<BodyTop>
							<CustomInputText
								label="Name of the Candidate"
								placeholder="Name"
								value={name}
								setValue={setName}
							/>
							<CustomInputText
								label="Email id"
								placeholder="sample@gmail.com"
								type="email"
								value={email}
								setValue={setEmail}
							/>
							<CustomInputRadio
								label="Gender"
								data={genderData}
								value={gender}
								setValue={setGender}
							/>
							<CustomInputDatePicker
								label="Date of Birth"
								value={dob}
								setValue={setDob}
							/>
							<CustomInputPhone
								label="Whatsapp Number"
								value={whatsApp}
								setValue={setWhatsApp}
								code={whatsAppCode}
								setCode={setWhatsAppCode}
							/>
							<CustomInputPhone
								label="Contact Number"
								value={phoneNumber}
								setValue={setPhoneNumber}
								code={phoneCode}
								setCode={setPhoneCode}
							/>
						</BodyTop>
						{/* <BottomHeading>Educational Details</BottomHeading> */}

						<BodyBottom>
							<BottomHeading>Educational Details</BottomHeading>

							<BottomItems>
								<CustomInputRadio
									label="Qualification"
									data={qualifications}
									isOther={true}
									value={education}
									setValue={setEducation}
									otherText={otherEducation}
									setOtherText={setOtherEducation}
								/>
							</BottomItems>

							<BottomHeading>Experiance Details</BottomHeading>

							<BottomItemsSelect>
								<CustomSelect
									label="Qualification"
									value={experiance}
									setValue={setExperiance}
								/>
							</BottomItemsSelect>
						</BodyBottom>
					</FormBody>
					<ButtonContainer>
						<CancelButton
							onClick={() => {
								navigate(-1);
							}}
						/>
						<SaveButton
							onClick={() => {
								submitRegisterForm();
							}}
						/>
					</ButtonContainer>
				</Container>
			</div>
			<ToastContainer />
		</MainContainer>
	);
}

export default ProfessionalRegistration;

const MainContainer = styled.div``;

const EmptyDiv = styled.div`
	background-color: #f5f8ff;
	position: absolute;
	top: 0;
	z-index: -1;
	right: 0;
	width: 0;
	height: 0;
	transition: width height 0.3s;
	animation: 0.8s in-out forwards;

	@keyframes in-out {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			height: 240px;
			width: 25%;
		}
	}

	@media all and (max-width: 1024px) {
		display: none;
	}
`;
const Container = styled.div`
	padding: 60px 100px;

	@media all and (max-width: 1280px) {
		padding: 60px;
	}
	@media all and (max-width: 1024px) {
		padding: 30px;
	}
	@media all and (max-width: 640px) {
		padding: 30px 0;
	}
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-bottom: 40px;

	h2 {
		@media all and (max-width: 480px) {
			text-align: center;
		}
	}
`;
const Line = styled.div`
	background-color: #76b82a;
	width: 70px;
	height: 8px;
	border-radius: 4px;
	margin-bottom: 15px;
`;
const FormBody = styled.div``;
const BodyTop = styled.div`
	border-top: 1px solid #d4d4d4;
	padding: 60px 0 30px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
	@media all and (max-width: 640px) {
		grid-template-columns: 1fr;
	}
`;
const BottomHeading = styled.h4`
	color: #6b6b6b;
	font-size: 18px;
	font-family: "DMSans-medium";
`;
const BodyBottom = styled.div`
	/* padding: 60px 0 40px; */
	margin-top: 20px;
`;
const BottomItems = styled.div`
	margin: 20px 0 40px;
`;
const BottomItemsSelect = styled.div`
	margin: 20px 0 40px;
	width: 49%;
	@media all and (max-width: 768px) {
		width: 100%;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
	margin-top: 40px;

	button:first-child {
		margin-right: 15px;
	}
`;
