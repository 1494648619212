import React from "react";
import styled from "styled-components";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";
import { Link } from "react-router-dom";

function OurTeams() {
	const members = [
		{
			id: 1,
			name: "John",
			designation: "UI Enginner",
			image: require("./../../../assets/images/person-1.jpg"),
		},

		{
			id: 2,
			name: "Ricky Smith",
			designation: "UI Designer",
			image: require("./../../../assets/images/person-2.jpg"),
		},
		{
			id: 3,
			name: "Daniel Hamilton",
			designation: "Product Designer",
			image: require("./../../../assets/images/person-3.jpg"),
		},

		{
			id: 4,
			name: "Katie Sims ",
			designation: "UX Designer",
			image: require("./../../../assets/images/person-2.jpg"),
		},
		{
			id: 5,
			name: "Katie Sims ",
			designation: "Product Manager",
			image: require("./../../../assets/images/person-1.jpg"),
		},
		{
			id: 6,
			name: "Ricky Smith",
			designation: "UI Designer",
			image: require("./../../../assets/images/person-2.jpg"),
		},
		{
			id: 7,
			name: "Daniel Hamilton",
			designation: "Product Designer",
			image: require("./../../../assets/images/person-3.jpg"),
		},
		{
			id: 8,
			name: "Katie Sims ",
			designation: "Product Manager",
			image: require("./../../../assets/images/person-1.jpg"),
		},
		{
			id: 9,
			name: "Ricky Smith",
			designation: "UI Designer",
			image: require("./../../../assets/images/person-2.jpg"),
		},
		{
			id: 10,
			name: "Daniel Hamilton",
			designation: "Product Designer",
			image: require("./../../../assets/images/person-3.jpg"),
		},
	];
	return (
		<MainContainer>
			<EmptyDiv />
			<div className="wrapper">
				<Container>
					<TopContainer>
						<TopDiv>
							<Line />

							<PrimaryHeading
								value={"Meet the Talented Individuals"}
								color="#0A0A0A"
							/>
							<HeadingDiv>
								<PrimaryHeading
									value={"Behind Our"}
									color="#0A0A0A"
								/>
								<PrimaryHeading
									value={"Success"}
									color="#4582f7"
								/>
							</HeadingDiv>
						</TopDiv>
					</TopContainer>
					<MembersDiv>
						<CardContainer>
							{members.map((item) => (
								<Card
									key={item.id}
									to={`/our-team/${item.name}`}
								>
									<Image src={item.image} alt="members" />
									<Name>{item.name}</Name>
									<Designation>
										{item.designation}
									</Designation>
								</Card>
							))}
						</CardContainer>
					</MembersDiv>
				</Container>
			</div>
			<OperationContainer>
				<div className="wrapper">
					<OperationDiv>
						<H3>Operations Team</H3>
						<OperationBody>
							<OperationTop>
								<BottomCard>
									<ImageDiv>
										<BottomImage
											src={require("./../../../assets/images/person-1.jpg")}
										/>
									</ImageDiv>
									<BottomName>Mrs Gowri Sreekanth</BottomName>
									<BottomDesignation>
										Head of Operations and Chief Counsellor
									</BottomDesignation>
								</BottomCard>
							</OperationTop>
							<OperationBottom>
								<OperationBottomDiv>
									<BottomLeft>
										<ImageDiv className="bottom">
											<BottomImage
												src={require("./../../../assets/images/person-1.jpg")}
											/>
										</ImageDiv>
									</BottomLeft>
									<BottomRight>
										<BottomName className="bottom">
											Mr Abdul Azeez Cherada
										</BottomName>
										<BottomDesignation className="bottom">
											Manager Client Relations, Counsellor
										</BottomDesignation>
									</BottomRight>
								</OperationBottomDiv>
								<OperationBottomDiv>
									<BottomLeft>
										<ImageDiv className="bottom">
											<BottomImage
												src={require("./../../../assets/images/person-1.jpg")}
											/>
										</ImageDiv>
									</BottomLeft>
									<BottomRight>
										<BottomName className="bottom">
											Mrs. Pooja Raj K
										</BottomName>
										<BottomDesignation className="bottom">
											Senior Executive- Client Relations
										</BottomDesignation>
									</BottomRight>
								</OperationBottomDiv>
							</OperationBottom>
						</OperationBody>
					</OperationDiv>
				</div>
			</OperationContainer>
		</MainContainer>
	);
}

export default OurTeams;

const MainContainer = styled.div``;

const EmptyDiv = styled.div`
	background-color: #f5f8ff;
	position: absolute;
	width: 0;
	height: 0;
	top: 0;
	z-index: -1;
	right: 0;
	transition: width height 0.3s;
	animation: 0.8s in-outz forwards;

	@keyframes in-outz {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			height: 200px;
			width: 31%;
		}
	}

	@media all and (max-width: 1024px) {
		display: none;
	}
`;
const Container = styled.div`
	padding: 60px 0;
`;
const TopContainer = styled.div`
	@media all and (max-width: 380px) {
		div > h2:nth-child(2) {
			text-align: center;
		}
	}
`;
const TopDiv = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const Line = styled.div`
	background-color: #76b82a;
	width: 70px;
	height: 8px;
	border-radius: 4px;
	margin-bottom: 15px;
`;
const HeadingDiv = styled.div`
	display: flex;
	align-items: center;
	h2:nth-child(1) {
		margin-right: 10px;
	}
`;
const MembersDiv = styled.div`
	padding: 60px 0 30px;
`;
const CardContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	gap: 20px;

	@media all and (max-width: 1280px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@media all and (max-width: 1024px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@media all and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
	@media all and (max-width: 380px) {
		grid-template-columns: 1fr;
	}
`;
const Card = styled(Link)`
	border: 1px solid #eeeeee;
	padding: 30px 40px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: #f5f8ff;
	}

	@media all and (max-width: 768px) {
		padding: 20px;
	}
`;
const Image = styled.img`
	height: 120px;
	width: 120px;
	border-radius: 50%;
	display: inline-block;
	object-fit: cover;

	@media all and (max-width: 480px) {
		height: 100px;
		width: 100px;
	}

	@media all and (max-width: 380px) {
		height: 120px;
		width: 120px;
	}
`;
const Name = styled.h5`
	text-align: center;
	color: #0a0a0a;
	font-size: 18px;
	margin-top: 5px;
`;
const Designation = styled.p`
	text-align: center;
	color: #747474;
	font-size: 14px;
`;
const OperationContainer = styled.div`
	background-color: #f5f8ff;
	padding: 60px 0 30px;
`;
const OperationDiv = styled.div``;
const H3 = styled.h3`
	color: #0a0a0a;
	font-size: 32px;
	text-align: center;
	font-family: "DMSans-medium";

	@media all and (max-width: 768px) {
		font-size: 28px;
	}

	@media all and (max-width: 480px) {
		font-size: 24px;
	}
`;
const OperationBody = styled.div`
	padding: 40px;
	@media all and (max-width: 1024px) {
		padding: 40px 30px;
	}
	@media all and (max-width: 768px) {
		padding: 40px 0px;
	}
	/* width: 80%; */
	/* margin: 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center; */
`;
const OperationTop = styled.div``;

const BottomCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 40px;
`;
const ImageDiv = styled.div`
	height: 200px;
	width: 200px;

	@media all and (max-width: 768px) {
		height: 170px;
		width: 170px;
	}
	@media all and (max-width: 480px) {
		height: 150px;
		width: 150px;
	}

	&.bottom {
		height: 180px;
		width: 180px;
		@media all and (max-width: 768px) {
			height: 150px;
			width: 150px;
		}
		@media all and (max-width: 480px) {
			height: 120px;
			width: 120px;
		}
	}
`;
const BottomImage = styled.img`
	height: 100%;
	border-radius: 50%;
	width: 100%;
	object-fit: cover;
`;
const BottomName = styled.h3`
	color: #0a0a0a;
	font-family: "DMSans-Medium";
	text-align: center;
	font-size: 18px;
	margin: 15px 0 5px;
	&.bottom {
		width: 80%;
		text-align: start;

		@media all and (max-width: 1024px) {
			width: 100%;
			text-align: center;
			font-size: 16px;
		}
		@media all and (max-width: 480px) {
			text-align: start;
			font-size: 15px;
		}
	}
`;
const BottomDesignation = styled.h4`
	color: #4582f7;
	font-family: "DMSans-Regular";
	text-align: center;
	font-size: 18px;
	&.bottom {
		text-align: start;
		width: 80%;
		@media all and (max-width: 1024px) {
			text-align: center;
			width: 100%;
			font-size: 16px;
		}
		@media all and (max-width: 480px) {
			text-align: start;
			font-size: 15px;
		}
	}
`;
const OperationBottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media all and (max-width: 480px) {
		flex-direction: column;
	}
`;
const OperationBottomDiv = styled.div`
	width: 45%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 30px 30px;
	border-right: 1px solid #d4d4d4;
	border-top: 1px solid #d4d4d4;

	&:last-child {
		border-right: 0;
	}
	&:first-child {
		padding-left: 0;
		@media all and (max-width: 480px) {
			border-top: 1px solid #d4d4d4;
		}
	}

	@media all and (max-width: 1024px) {
		flex-direction: column;
		justify-content: start;
		align-self: stretch;
		width: 50%;
	}
	@media all and (max-width: 480px) {
		width: 100%;
		border: none;
		flex-direction: row;
		padding: 30px 0;
	}
`;
const BottomLeft = styled.div``;
const BottomRight = styled.div`
	margin-left: 15px;
`;
