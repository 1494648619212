import React, { useEffect } from "react";
import styled from "styled-components";
import SecondaryHeading from "../../includes/heading/SecondaryHeading";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";
import bgImage from "./../../../assets/images/service-bg-1.png";

//aos
import AOS from "aos";
import "aos/dist/aos.css";

function HomeService() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	const details = [
		{
			id: "01",
			title: "Child by the Age of Six",
			description:
				"Character formation happens in a child by the age of six. It implies that the personality development of a child begins by this age",
		},
		{
			id: "02",
			title: "Education can help out the best talents in a student",
			description:
				"Education can help in drawing out the best talents in a student. Hence it is important to understand the interests and passion of a child, which when identified correctly could be channelled in the right direction. This leads him to a career that helps to derive happiness and satisfaction in life",
		},
		{
			id: "03",
			title: "Identify personality traits, interests, passion",
			description:
				"Education can help in drawing out the best talents in a student. Hence it is important to understand the interests and passion of a child, which when identified correctly could be channelled in the right direction. This leads him to a career that helps to derive happiness and satisfaction in life",
		},
	];

	return (
		<MainContainer
		// data-aos="fade-up"
		>
			<div className="wrapper">
				<SecondaryHeading
					value={"Services"}
					lineColor={"rgba(173, 173, 173, 0.5)"}
				/>
				<Container>
					<LeftDiv>
						<PrimaryHeading
							value={"Rationale for the Students to"}
							color="#fff"
						/>
						<HeadingDiv>
							<PrimaryHeading value={"join"} color="#fff" />
							<PrimaryHeading
								value={"GoalFinder Path"}
								color="#4582f7"
							/>
						</HeadingDiv>

						<ListSecttion>
							{details.map((item) => (
								<ListDiv key={item.id}>
									<ListLeft>
										<Count>{item.id}</Count>
									</ListLeft>
									<ListRight>
										<Title>{item.title}</Title>
										<Paragraph>
											{item.description}
										</Paragraph>
									</ListRight>
								</ListDiv>
							))}
						</ListSecttion>
					</LeftDiv>
					<RightDiv>
						<Image
							src={require("./../../../assets/images/home-service.png")}
							alt="image"
						/>
					</RightDiv>
				</Container>
			</div>
		</MainContainer>
	);
}

export default HomeService;

const MainContainer = styled.div`
	background-color: rgba(1, 17, 45, 1);
	padding: 40px 0;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-image: url(${bgImage});
	background-repeat: no-repeat;
	background-size: 75% 80%;
`;
const Container = styled.div`
	display: flex;
	align-items: end;
	justify-content: space-between;
	@media all and (max-width: 768px) {
		flex-direction: column;
	}
`;
const LeftDiv = styled.div`
	width: 48%;

	@media all and (max-width: 1024px) {
		width: 48%;
	}
	@media all and (max-width: 768px) {
		width: 100%;
	}
`;
const HeadingDiv = styled.div`
	display: flex;
	align-items: center;
	h2:nth-child(1) {
		margin-right: 10px;
		@media all and (max-width: 768px) {
			width: auto;
		}
	}
`;
const ListSecttion = styled.div`
	margin-top: 30px;
`;
const ListDiv = styled.div`
	padding: 20px 0;
	display: flex;
	align-items: start;
	border-bottom: 1px solid rgba(173, 173, 173, 0.3);

	:last-child {
		border: none;
	}
`;
const ListLeft = styled.div`
	margin-right: 20px;
`;
const Count = styled.span`
	background-color: rgba(230, 237, 250, 0.8);
	color: rgba(10, 10, 10, 1);
	font-size: 16px;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
`;
const ListRight = styled.div``;
const Title = styled.h4`
	color: rgba(241, 251, 248, 1);
	font-size: 20px;
	margin-bottom: 8px;
`;
const Paragraph = styled.p`
	color: rgba(212, 212, 212, 1);
	font-size: 15px;
`;
const RightDiv = styled.div`
	width: 48%;
	padding: 30px 0;
	display: flex;
	align-items: end;
	justify-content: end;
	@media all and (max-width: 1024px) {
		width: 50%;
	}
	@media all and (max-width: 768px) {
		width: 100%;
		justify-content: center;
	}
`;
const Image = styled.img`
	/* width: 90%; */
	display: inline-block;
	/* height: 100%; */
	/* aspect-ratio: 10/9; */
	width: 80%;

	@media all and (max-width: 1024px) {
		aspect-ratio: 7/9;
		width: 90%;
	}

	@media all and (max-width: 768px) {
		width: 60%;
	}
	@media all and (max-width: 480px) {
		width: 70%;
	}
`;
