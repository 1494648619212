import React, { useRef } from "react";
import styled from "styled-components";

function CustomInputRadio({
	label,
	data,
	value,
	setValue,
	isOther = false,
	otherText,
	setOtherText,
}) {
	const inputRef = useRef(null);

	const handleChange = (item) => {
		setValue(item.value);
		if (isOther) {
			setOtherText("");
		}
	};
	return (
		<Container>
			<Label>{label}</Label>
			<InputContainer>
				<RadioDiv>
					{data.map((item, i) => (
						<ItemDiv
							key={i}
							onClick={() => {
								handleChange(item);
							}}
						>
							<OuterCircle
								className={
									value === item.value ? "selected" : ""
								}
							>
								<InnerCircle
									className={
										value === item.value ? "selected" : ""
									}
								/>
							</OuterCircle>
							<Text
								className={
									value === item.value ? "selected" : ""
								}
							>
								{item.text}
							</Text>
						</ItemDiv>
					))}
				</RadioDiv>
				{isOther && (
					<OtherContainer>
						<OtherDiv>
							<ItemDiv
								onClick={() => {
									setValue("other");
									if (inputRef.current) {
										inputRef.current.focus();
									}
								}}
							>
								<BottomOuterCircle
									className={
										value === "other" ? "selected" : ""
									}
								>
									<BottomInnerCircle
										className={
											value === "other" ? "selected" : ""
										}
									/>
								</BottomOuterCircle>
								<Text
									className={
										value === "other" ? "selected" : ""
									}
								>
									Other
								</Text>
							</ItemDiv>
							<InputText
								ref={inputRef}
								value={otherText}
								onChange={(e) => {
									if (value === "other") {
										setOtherText(e.target.value);
									}
								}}
							/>
						</OtherDiv>
					</OtherContainer>
				)}
			</InputContainer>
		</Container>
	);
}

export default CustomInputRadio;

const Container = styled.div``;
const Label = styled.label`
	color: #6b6b6b;
	font-size: 15px;
	display: block;
	margin-bottom: 5px;
`;
const InputContainer = styled.div`
	border: 1px solid #d9d9d9;
	font-size: 16px;
	border-radius: 8px;
	padding: 0px 12px;
`;
const Input = styled.input`
	height: 48px;
	border: 1px solid #d9d9d9;
	font-size: 16px;
	border-radius: 8px;
	padding-left: 12px;
	color: #494a4a;
	outline: none;
	width: auto;
	transition: border-color 0.3s;

	&::placeholder {
		color: #adadad;
	}

	&:focus {
		border-color: #4582f7;
	}
`;
const RadioDiv = styled.div`
	min-height: 48px;
	font-size: 16px;
	display: flex;
	align-items: center;
	gap: 15px 30px;
	flex-wrap: wrap;
	padding: 10px 0;

	@media all and (max-width: 768px) {
		gap: 20px;
	}
`;
const ItemDiv = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	flex-wrap: wrap;
`;
const OuterCircle = styled.div`
	height: 18px;
	width: 18px;
	background-color: #fff;
	border: 1px solid #afafaf;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-right: 8px;

	&.selected {
		border-color: #4582f7;
	}
`;
const InnerCircle = styled.div`
	height: 12px;
	border-radius: 50%;
	width: 12px;

	&.selected {
		background-color: #4582f7;
	}
`;
const Text = styled.h5`
	color: #afafaf;
	font-family: "DMSans-Medium";
	font-size: 14px;

	&.selected {
		color: #4582f7;
	}
`;
const OtherContainer = styled.div`
	height: 48px;
	margin-bottom: 10px;
`;
const OtherDiv = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;
const InputText = styled.input`
	margin-left: 12px;
	border-bottom: 1px solid #d9d9d9;
	width: 90%;
	appearance: none;
	box-sizing: border-box;
	color: #494a4a;
	font-size: 16px;
`;
const BottomOuterCircle = styled.span`
	height: 18px;
	width: 18px;
	background-color: #fff;
	border: 1px solid #afafaf;
	/* display: flex;
	align-items: center; */
	justify-content: center;
	border-radius: 50%;
	margin-right: 8px;
	display: flex;
	align-items: center;
	justify-content: center;

	&.selected {
		border-color: #4582f7;
	}
`;
const BottomInnerCircle = styled.span`
	height: 12px;
	border-radius: 50%;
	width: 12px;

	&.selected {
		background-color: #4582f7;
	}
`;
