import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

//aos
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function LandingSection() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	const [height, setHeight] = useState(0);
	const ref = useRef(null);

	useEffect(() => {
		setHeight(ref.current.clientHeight);
	});

	return (
		<MainContainer
			ref={ref}
			// data-aos="fade-up"
		>
			{/* <EmptyContainer className="wrapper"> */}
			<EmptyDiv height={height} />
			{/* </EmptyContainer> */}
			<div className="wrapper">
				<Container>
					<LeftDiv>
						<PrimaryHeading value={"Let’s Craft Your"} />
						<PrimaryHeading
							value={"Career and Life"}
							color="#4582f7"
						/>

						<Paragraph>
							The GoalFinder is an online tool that allows
							students and professionals to evaluate their own
							skills, potential, interests, and areas for
							improvement. Self-Assessment Systems and Regular
							Self-Improvement Programs facilitate the process. It
							is the mission of GoalFinder to help young minds
							identify their talents and interests so that they
							may reach their desired goals in order to lead a
							fulfilling and happy life.
						</Paragraph>
						<ButtonDiv>
							<PrimaryButton to="https://aspirants.goalfinder.org/student-dashboard/suitability-registration/login/MjA2OQ==/as11-as12-as13">
								Goal Finder Juniors
							</PrimaryButton>
							<SecondaryButton to="https://aspirants.goalfinder.org/student-dashboard/suitability-registration/login/MjA2OQ==/as14-as204">
								Goal Finder Professionals
							</SecondaryButton>
						</ButtonDiv>
					</LeftDiv>
					<RightDiv>
						<LandingImageDiv>
							<LandingImage
								src={require("./../../../assets/images/landing-women.png")}
								alt="image"
							/>
						</LandingImageDiv>
					</RightDiv>
				</Container>
			</div>
		</MainContainer>
	);
}

export default LandingSection;

const MainContainer = styled.div``;

const EmptyContainer = styled.div`
	position: relative;
`;
const EmptyDiv = styled.div`
	background-color: #ffeece;
	position: absolute;
	top: 0;
	z-index: -2;
	right: 0;
	width: 0;
	height: 0;
	transition: width height 0.3s;
	animation: 0.8s in-outs forwards;

	@keyframes in-outs {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			height: 789px;
			width: 31%;
		}
	}

	@media all and (max-width: 1024px) {
		@keyframes in-outs {
			0% {
				width: 0;
				height: 0;
			}
			100% {
				height: 789px;
				width: 40%;
			}
		}
	}

	@media all and (max-width: 768px) {
		@keyframes in-outs {
			0% {
				width: 0;
				height: 0;
			}
			100% {
				height: 1000px;
				width: 50%;
			}
		}
	}
`;
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media all and (max-width: 768px) {
		flex-direction: column;
	}
`;
const LeftDiv = styled.div`
	width: 60%;
	display: flex;
	align-items: start;
	justify-content: center;
	flex-direction: column;
	/* height: calc(789px - 100px); */
	min-height: calc(100vh - 100px);

	@media all and (max-width: 768px) {
		width: 100%;
	}
`;
const Paragraph = styled.p`
	color: #747474;
	font-size: 18px;
	margin: 20px 0 40px;
	width: 80%;
	@media all and (max-width: 768px) {
		font-size: 16px;
	}
	@media all and (max-width: 480px) {
		width: 100%;
		font-size: 15px;
	}
`;
const ButtonDiv = styled.div`
	display: flex;
	align-items: center;
	@media all and (max-width: 480px) {
		width: 100%;
	}
`;
const PrimaryButton = styled(Link)`
	color: #fff;
	border: 1px solid #4582f7;
	background-color: #4582f7;
	border-radius: 6px;
	height: 50px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 16px;
	margin-right: 15px;

	@media all and (max-width: 480px) {
		font-size: 13px;
		/* height: 40px; */
		width: 45%;
		padding: 0 8px;
		margin-right: 8px;
	}
	@media all and (max-width: 360px) {
		font-size: 12px;
	}
`;
const SecondaryButton = styled(Link)`
	color: #4582f7;
	border: 1px solid #4582f7;
	background-color: #fff;
	border-radius: 6px;
	height: 50px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 16px;

	@media all and (max-width: 480px) {
		font-size: 13px;
		/* height: 40px; */
		width: 55%;
		padding: 0 8px;
	}
	@media all and (max-width: 360px) {
		font-size: 12px;
	}
`;
const RightDiv = styled.div`
	width: 40%;
	height: calc(789px - 100px);
	position: relative;
	@media all and (max-width: 768px) {
		width: 100%;
		height: 450px;
	}
	@media all and (max-width: 480px) {
		height: 380px;
	}
`;
const LandingImageDiv = styled.div`
	height: 90%;
	width: 90%;
	position: absolute;
	bottom: 0;
	right: 40px;

	@media all and (max-width: 768px) {
		width: 50%;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}
	@media all and (max-width: 480px) {
		width: 65%;
	}
`;
const LandingImage = styled.img`
	height: 100%;
	width: 100%;
	display: block;
`;
