import React from "react";
import styled from "styled-components";

function Counter() {
	const couterValue = [
		{
			id: 1,
			count: "2+",
			text: "Year of Experiance",
		},
		{
			id: 2,
			count: "1,800+",
			text: "Psychometric Test",
		},
		{
			id: 3,
			count: "800+",
			text: "Counselling",
		},
	];

	return (
		<MainContainer>
			<div className="wrapper">
				<Container>
					<LeftDiv>
						<Heading>Shaping Futures Through</Heading>
						<Heading className="bold">Goal Finder</Heading>
					</LeftDiv>
					<RightDiv>
						{couterValue.map((item, i) => (
							<React.Fragment key={i}>
								<CounterDiv>
									<Count>{item.count}</Count>
									<CountText>{item.text}</CountText>
								</CounterDiv>
								<Hr />
							</React.Fragment>
						))}
					</RightDiv>
				</Container>
			</div>
		</MainContainer>
	);
}

export default Counter;

const MainContainer = styled.div`
	background-color: #f5f8ff;
	padding: 20px 0;
`;
const Container = styled.div`
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media all and (max-width: 768px) {
		flex-direction: column;
	}
`;
const LeftDiv = styled.div`
	display: flex;
	align-items: start;
	height: 100%;
	width: 55%;
	flex-direction: column;
	justify-content: center;

	@media all and (max-width: 1024px) {
		width: 38%;
	}

	@media all and (max-width: 768px) {
		width: 100%;
	}
`;
const Heading = styled.h3`
	color: #0a0a0a;
	font-weight: 500;
	font-size: 28px;
	display: block;
	&.bold {
		font-weight: 600;
	}

	@media all and (max-width: 480px) {
		font-size: 22px;
	}
`;
const RightDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 45%;

	@media all and (max-width: 1024px) {
		width: 58%;
	}
	@media all and (max-width: 768px) {
		width: 100%;
	}
	@media all and (max-width: 380px) {
		align-items: start;
	}
`;
const CounterDiv = styled.div`
	@media all and (max-width: 380px) {
		padding: 0 8px;
	}
`;
const Count = styled.h3`
	color: #0a0a0a;
	font-size: 32px;
	font-family: "DMSans-Bold";

	@media all and (max-width: 768px) {
		font-size: 26px;
	}
	@media all and (max-width: 480px) {
		font-size: 22px;
	}
`;
const CountText = styled.h6`
	color: #747474;
	font-size: 15px;

	@media all and (max-width: 768px) {
		font-size: 14px;
	}

	@media all and (max-width: 380px) {
		width: 80%;
	}
`;
const Hr = styled.div`
	height: 55px;
	width: 2px;
	background-color: #7474744d;
	:last-child {
		display: none;
	}
`;
