import React from "react";
import styled from "styled-components";
import SecondaryHeading from "../../includes/heading/SecondaryHeading";
import PrimaryHeading from "../../includes/heading/PrimaryHeading";

/////////////////////////////////////////////

function Messages({ data, reverse = false }) {
	return (
		<MainContainer>
			<div className="wrapper">
				<Container className={reverse ? "reverse" : ""}>
					<Left>
						<PlainDiv></PlainDiv>
					</Left>
					<Right>
						<SecondaryHeading
							value={"Message From"}
							lineColor={"#D4D4D4"}
						/>
						<HeadingDiv>
							<PrimaryHeading
								value={data?.first_heading}
								color="#0A0A0A"
							/>
							<PrimaryHeading
								value={data?.second_heading}
								color="#4582f7"
							/>
						</HeadingDiv>
						<ParagraphDiv>
							<Paragraph>{data?.top_message}</Paragraph>
							<BlueIcon
								src={
									require("./../../../assets/icons/review-slash.svg")
										.default
								}
								alt="icon"
							/>
						</ParagraphDiv>
						<SignDiv>
							<Sign src={data?.sign} alt="sign" />
						</SignDiv>
						<Name>{data?.name}</Name>
					</Right>
				</Container>
			</div>
			<BottomContainer>
				<div className="wrapper">
					<BottomDiv className={reverse ? "reverse" : ""}>
						<BottomLeft>
							<UserSection>
								<UserDiv>
									<User src={data.image} alt="image" />
								</UserDiv>
							</UserSection>
						</BottomLeft>
						<BottomRight>
							<BottomParagraph>
								<Span>{data?.name} &nbsp;</Span>
								{data?.bottom_message}
							</BottomParagraph>
						</BottomRight>
					</BottomDiv>
				</div>
			</BottomContainer>
		</MainContainer>
	);
}

export default Messages;

const MainContainer = styled.div``;
const Container = styled.div`
	margin: 80px 0 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&.reverse {
		flex-direction: row-reverse;
	}
`;
const Left = styled.div`
	width: 38%;
	align-self: stretch;

	@media all and (max-width: 1024px) {
		display: none;
	}
`;
const PlainDiv = styled.div`
	background-color: #f5f8ff;
	height: 100%;
	width: 90%;
`;

const Right = styled.div`
	width: 58%;
	min-height: 100%;
	@media all and (max-width: 1024px) {
		width: 100%;
	}
`;

const HeadingDiv = styled.div`
	display: flex;
	align-items: center;
	h2:nth-child(1) {
		margin-right: 10px;
	}
`;
const ParagraphDiv = styled.div`
	margin: 30px 0 15px;
	position: relative;
`;
const Paragraph = styled.p`
	color: #747474;
	font-size: 16px;
	font-style: italic;
	width: 90%;
	@media all and (max-width: 1024px) {
		width: 100%;
	}
	@media all and (max-width: 480px) {
		font-size: 15px;
	}
`;
const BlueIcon = styled.img`
	position: absolute;
	opacity: 0.5;
	top: -3px;
	left: -20px;
	width: 30px;
`;
const SignDiv = styled.div``;
const Sign = styled.img``;
const Name = styled.h4`
	color: #0a0a0a;
	font-family: "DMSans-Bold";
	font-size: 18px;
`;

const BottomContainer = styled.div`
	background-color: #01112d;
`;
const BottomDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* position: relative; */
	position: relative;

	&.reverse {
		flex-direction: row-reverse;
		/* @media all and (max-width: 768px) {
			flex-direction: column-reverse;
		} */
	}
	@media all and (max-width: 768px) {
		flex-direction: column-reverse;
		&.reverse {
			flex-direction: column-reverse;
		}
		/* flex-direction: column; */
	}
`;
const BottomLeft = styled.div`
	width: 38%;
	align-self: stretch;

	@media all and (max-width: 768px) {
		width: 100%;
		height: 350px;
		margin: auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@media all and (max-width: 480px) {
		/* height: 300px; */
	}
`;

const UserSection = styled.div`
	/* width: 38%; */
	position: absolute;
	bottom: 0;
	width: 38%;
	@media all and (max-width: 768px) {
		height: 350px;
		width: 50%;
	}

	@media all and (max-width: 620px) {
		width: 60%;
	}
	@media all and (max-width: 480px) {
		/* height: 300px; */
		width: 70%;
	}
`;
const UserDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	@media all and (max-width: 786px) {
		height: 100%;
	}
`;
const User = styled.img`
	width: 90%;
	@media all and (max-width: 786px) {
		/* aspect-ratio: 1/2; */
		height: 100%;
	}
	@media all and (max-width: 480px) {
		width: 90%;
	}
	@media all and (max-width: 400px) {
		width: 100%;
	}
`;
const BottomRight = styled.div`
	width: 58%;
	padding: 30px 0;
	/* @media all and (max-width: 768px) {
	} */

	@media all and (max-width: 768px) {
		/*
		height: 400px;
		background-color: red; */
		width: 100%;
	}
`;

const BottomParagraph = styled(Paragraph)`
	color: #eeeeee;
	font-style: normal;
	margin: 0;
	width: 100%;
`;
const Span = styled.small`
	display: inline-block;
	color: #4582f7;
	font-size: 18px;
	font-family: "DMSans-Bold";
`;
