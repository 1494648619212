import React from "react";
import styled from "styled-components";
import AboutUsLanding from "./AboutUsLanding";
import Counter from "../home/Counter";
import Messages from "./Messages";
import BoardMember from "./BoardMember";

//imgaes

function AboutUs() {
	const ThomasDetails = {
		name: "Dr Thomas George K",
		first_heading: "Our",
		second_heading: "Chairman",
		top_message:
			"We believe that every child and professional has the potential to achieve what they desire if they are given the freedom to explore, express, discover their talents, strengths and passions. The greatest hurdle facing a person on the road to success is the lack of support and guidance. We at Goal Finder are dedicated to providing people with the support and assistance they require to attain success, aiding you to reach your desired goals, identifying those intrinsic talents you possess and contouring them through the right guidance so you can reach your desired goals. Throughout each phase of the academic/professional journey, our Mentors assist them in identifying, correcting the gaps in knowledge, and making informed decisions at every stage of their journey through personalized guidance in areas where they may excel or need extra help along with parental support, so they will be able to succeed in their academic/professional pursuits. It is a pleasure to receive your trust in Goal Finder to nurture your needs, and I would like to assure you that we will do everything within our capacity to help them reach their dreams, and to enhance their support as they are challenged, and this will always remain my personal commitment to you.",
		bottom_message:
			'is an engineer turned Trainer and Edupreneur with immense interest in psychology and management education. He is a Guinness world record holder for conducting the longest business lessons in the world. Recently, in 2023, Dr. Thomas George K has written a book titled "Preneur" on entrepreneurship and business management where he shares his experiences and insights as a successful businessman and educator as also provides practical tips and guidance for starting or expanding a business. Dr. Thomas evolved himself as a ‘Chief Mentor’ for a rigorous life skill training program called “turning point” where he has completed 308 batches comprised of 30000+ participants and it became one of the iconic programs that captivated the attention of the participants, institutions, corporate and the community at large.',

		image: require("./../../../assets/images/Thomas.png"),
		sign: require("./../../../assets/images/Thomas-sign.png"),
	};

	const SreekanthDetails = {
		name: "Dr Sreekanth S V",
		first_heading: "Executive",
		second_heading: "Director",
		top_message:
			"As we live in a world of dynamic and volatile disruptions, self-identification is an essential matter to become successful in this world. Getting this right is a challenge that every person faces, and so is figuring out how to do this successfully. As a person, it is not easy to identify the skills, interests, and abilities that are a part of one's self in a scientific manner. It would be highly beneficial if a person could receive expert assistance with the technical analysis of his or her self and then develop a professional path that is most suitable for him or her. As you can see from the name, Goal Finder is a well curated tool that enables an individual to evaluate their capabilities and develop a career path that meets their needs. The most important aspect of every professional's life is that he or she spends a considerable amount of time on their professional assignments which constitute a significant part of their productive hours. The development of a child's career path usually begins during the school years, which indicates that parents are the primary catalysts in determining which career path is the best fit for each child. Consequently, I encourage every parent to collaborate with Goal Finder Path so that they can support their child's career development and lead a happy and prosperous life.",
		bottom_message:
			"is an academician, administrator, and entrepreneur with expertise in the field of behavioural science. In the past decade, he has proven his track record as a director of one of the leading business schools in India as well as a professor at a couple of B schools and a consultant to different industries. Currently, he is a key member of the Strategic Formulation Team at Lead Business School, Palakkad, Kerala, India. Sreekanth has experience in counselling, behavioural modification techniques, neuro-linguistic programming, and transactional analysis methodologies, and he is actively engaged in a behavioural science as also social innovation research projects.",

		image: require("./../../../assets/images/Sreekanth.png"),
		sign: require("./../../../assets/images/Sreekanth-sign.png"),
	};

	return (
		<MainContainer>
			<AboutUsLanding />
			<Counter />
			<Messages data={ThomasDetails} />
			{/* <Messages reverse={true} data={SreekanthDetails} /> */}
			{/* <BoardMember /> */}
		</MainContainer>
	);
}

export default AboutUs;

const MainContainer = styled.div``;
