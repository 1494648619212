import React from "react";
import styled from "styled-components";

function CustomSelect({ label, value, setValue }) {
	const handleChange = (e) => {
		setValue(e.target.value);
	};

	return (
		// <>
		// 	<Label>{label}</Label>

		<SelectContainer>
			<Select
				onChange={handleChange}
				value={value}
				// className={value[props.item.key] === "" ? "" : "selected"}
			>
				<option value="0-2">0-2</option>
				<option value="2-5">2-5</option>
				<option value="5-7">5-7</option>
				<option value="7-10">7-10</option>
				<option value="10-15">10-15</option>
				<option value="15+">15+</option>
			</Select>
		</SelectContainer>
		// </>
	);
}

export default CustomSelect;

const SelectContainer = styled.div`
	position: relative;
	height: 48px;
`;

const Label = styled.label`
	color: #6b6b6b;
	font-size: 15px;
	display: block;
	margin-bottom: 5px;
`;

const Select = styled.select`
	height: 48px;
	width: 100%;
	padding: 10px;
	border: 1px solid #cccccc;
	border-radius: 8px;
	/* appearance: none; */
	background-color: transparent;
	cursor: pointer;
	color: #767b85;
	font-size: 15px;
	font-family: "PTSans-Regular";

	&.selected {
		color: #000;
	}

	&::placeholder {
		color: #767b85;
		font-family: "PTSans-Regular";
	}
`;

const ArrowIcon = styled.img`
	position: absolute;
	top: 10px;
	right: 10px;
	width: 20px;
	height: 20px;
	/* position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	
	border-top: 2px solid #000000;
	border-right: 2px solid #000000;
	transition: transform 0.3s ease-in-out;
	pointer-events: none;
	transform-origin: center;
	${({ open }) => open && "transform: translateY(-50%) rotate(180deg);"} */
`;
